import { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import CheckedIcon from '@material-ui/icons/CheckBox'
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CloudDownload from '@material-ui/icons/CloudDownload'
import EditIcon from '@material-ui/icons/Edit'
import ViewPageIcon from '@material-ui/icons/Pageview'
import PlayIcon from '@material-ui/icons/PlayArrow'

import * as AdminProjectsActions from '../AdminProjects/AdminProjectsActions'
import * as AdminPartsActions from './AdminPartsActions'
import { EDIT_PART_ADMIN_ROUTE } from '../../../../Services/Constants/RoutesConstants'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import { partConfigurationsRoute } from '../../../../Services/routeFuncs'
import {
	ADMIN_PARTS_AMOUNT_IN_ASSMBLY,
	ADMIN_PARTS_AREA,
	ADMIN_PARTS_CAD_SCORE,
	ADMIN_PARTS_EXTERNAL_ID,
	ADMIN_PARTS_IS_OFF_THE_SHELF,
	ADMIN_PARTS_ORIGINAL_MATERIAL,
	ADMIN_PARTS_PART_ID,
	ADMIN_PARTS_RESULT,
	ADMIN_PARTS_SIZE,
	ADMIN_PARTS_SIZE_SCORE,
	ADMIN_PARTS_STATUS,
	ADMIN_PARTS_VOLUME,
	ADMIN_PARTS_WALL_THICKNESS_SCORE,
	DOWNLOAD_PART,
	EDIT_PART,
	GO,
	OK,
	PART_COMPLEXITY_SCORE,
	PART_IS_COMPLEX,
	PART_IS_NOT_COMPLEX,
	RATE_3D,
	SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER,
	VIEW_PART
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import CastorAlert from '../../../Components/alerts/CastorAlert'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import Table from '../../../Components/thirdParty/CreativeTim/components/Table/Table.jsx'
import Loader from '../../../Loader/Loader'
import AdminClusters from '../AdminProjects/AdminClusters'
import AdminProjectForm from '../AdminProjects/AdminProjectForm'
import { actionsENUM } from './actionsEnum'
import Flexbox from 'Scenes/Components/FlexBox'

import './AdminParts.scss'

// get a string for  each enum
const actionToString = action => {
	switch (action) {
		case actionsENUM.DELETE_PARTS:
			return getString('DELETE_PARTS')
		case actionsENUM.COMBINE_PARTS:
			return getString('COMBINE_PARTS')
		default:
			return ''
	}
}

class AdminParts extends Component {
	//Lifecycle functions
	componentDidMount() {
		this.props.setupAdminPartsPage(this.props.match.params.projectId)
	}

	componentDidUpdate(prevProps) {
		if (this.props.stlDownloadURL) {
			window.open(this.props.stlDownloadURL)
			this.props.downloadedSTLComplete()
		}
	}

	//Actions
	actionChanged(event) {
		this.props.selectAction(event.target.value)
	}

	downloadSTLClicked(part) {
		this.props.downloadSTL(part)
	}

	checkboxClicked(part) {
		this.props.checkboxSelected(part)
	}

	runAction() {
		this.props.submittedAction(
			this.props.selectedAction,
			this.props.match.params.projectId,
			this.props.selectedPartsIds
		)
	}

	//Render functions
	renderAlert() {
		if (!this.props.showingSimpleAlertTitle) {
			return <div />
		}

		let body = this.props.showingSimpleAlertText

		return (
			<CastorAlert
				headerTitle={this.props.showingSimpleAlertTitle}
				onConfirm={() =>
					this.props.publishConfirmed(this.props.projectWaitingForPublishing)
				}
				onCancel={() => this.props.cancelAlert()}
				confirmOptionalText={OK}
			>
				{body}
			</CastorAlert>
		)
	}

	tableLine(part, editPart) {
		return [
			<Checkbox
				tabIndex={-1}
				onClick={this.checkboxClicked.bind(this, part)}
				checked={this.props.selectedPartsIds.includes(part.id)}
				checkedIcon={<CheckedIcon />}
				icon={<UncheckedIcon />}
			/>,
			<div>
				<img
					src={
						part.wallThiknessImageURL
							? part.wallThiknessImageURL
							: part.imageURL
					}
					style={styles.thumbnail}
				/>
			</div>,
			<div>
				<h4> {part.partNumber} </h4>
				<p>
					{ADMIN_PARTS_PART_ID}: {part.id}. {ADMIN_PARTS_EXTERNAL_ID}:{' '}
					{part.externalId}
				</p>
				<p>
					{ADMIN_PARTS_AMOUNT_IN_ASSMBLY}: {part.countInAssembly}.{' '}
					{ADMIN_PARTS_IS_OFF_THE_SHELF}: {part.isOffTheShelf}
				</p>
				<p>
					{ADMIN_PARTS_SIZE}:{' '}
					{`${part.width} * ${part.height} * ${part.depth} mm`}
				</p>
				<p>{`${ADMIN_PARTS_VOLUME}: ${part.volume}. ${ADMIN_PARTS_AREA}: ${part.area}.`}</p>
			</div>,
			<div>
				<p>
					{ADMIN_PARTS_RESULT}: {part.result} {ADMIN_PARTS_STATUS}:{' '}
					{part.status}
				</p>
				<p>
					{ADMIN_PARTS_ORIGINAL_MATERIAL}:{' '}
					{part.material ? part.material.name : ''}
				</p>
				<p>
					{`${ADMIN_PARTS_WALL_THICKNESS_SCORE}:${part.wallThicknessScore}. ${ADMIN_PARTS_SIZE_SCORE}:${part.sizeScore}. ${ADMIN_PARTS_CAD_SCORE}: ${part.CADScore}`}
				</p>
				{part.displayComplex && (
					<>
						<p>
							<b>{PART_COMPLEXITY_SCORE}:</b> {part.partComplexityScore}
						</p>
						<p>
							<b>{part.isComplex ? PART_IS_COMPLEX : PART_IS_NOT_COMPLEX}</b>
						</p>
					</>
				)}
				{part.rate && (
					<p>
						<b>{RATE_3D}</b> {part.rate}
					</p>
				)}
			</div>,
			this.renderTableLineButtons(part, editPart)
		]
	}

	static wrapButtonWithLinkIfEnabled(button, link) {
		return button.props.disabled ? button : <Link to={link}>{button}</Link>
	}

	renderTableLineButtons(part, editPart) {
		const isAdmin = this.props.user?.userDetails?.admin

		const downloadSTL = (
			<IconButton
				title={DOWNLOAD_PART}
				onClick={this.downloadSTLClicked.bind(this, part)}
			>
				<CloudDownload />
			</IconButton>
		)
		const viewButton =
			part?.isTopLevelAssembly || (part.lock && !isAdmin) ? (
				<></>
			) : (
				AdminParts.wrapButtonWithLinkIfEnabled(
					<IconButton title={VIEW_PART}>
						<ViewPageIcon />
					</IconButton>,
					partConfigurationsRoute(part.projectId, part.id)
				)
			)
		const editButtonLinkTo = {
			pathname: `${EDIT_PART_ADMIN_ROUTE}/${part.id}`,
			part
		}
		const editButton =
			!part?.isTopLevelAssembly && editPart ? (
				AdminParts.wrapButtonWithLinkIfEnabled(
					<IconButton title={EDIT_PART}>
						<EditIcon />
					</IconButton>,
					editButtonLinkTo
				)
			) : (
				<></>
			)

		return (
			<Flexbox flexDirection="column" alignItems="center">
				{editButton}
				{viewButton}
				{downloadSTL}
			</Flexbox>
		)
	}

	renderTable(editPart) {
		const {
			selectedAction,
			match,
			parts,
			error,
			getMoreProjectParts,
			projectHasMoreParts,
			loading,
			setMaxHeightForPartsTable
		} = this.props
		const tableHeaders = [
			'',
			'',
			getString('PART_INFORMATION'),
			getString('RESULTS'),
			getString('ACTIONS')
		]
		const tableContent = parts.map(part => this.tableLine(part, editPart))
		return (
			<div>
				<p style={styles.errorTextStyle}>{error}</p>
				{editPart && (
					<Flexbox flexDirection="row" style={styles.actionSelectFlex}>
						<FormControl style={{ paddingBottom: 13 }}>
							<InputLabel htmlFor="age-simple">
								{SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER}
							</InputLabel>
							<Select
								style={styles.actionSelect}
								classes={{}}
								value={selectedAction}
								floatingLabelText={actionToString(selectedAction)}
								onChange={this.actionChanged.bind(this)}
								inputProps={{
									name: 'simpleSelect',
									id: 'simple-select'
								}}
								MenuProps={{
									PaperProps: {
										style: {
											transform: 'translate3d(0, 0, 0)'
										}
									}
								}}
							>
								<MenuItem disabled>
									{SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER}
								</MenuItem>

								{Object.values(actionsENUM).map(action => {
									return (
										<MenuItem value={action}>{actionToString(action)}</MenuItem>
									)
								})}
							</Select>
						</FormControl>
						<Button
							size="sm"
							style={styles.runActionButton}
							onClick={this.runAction.bind(this)}
							color="primary"
						>
							<PlayIcon /> {GO}
						</Button>
					</Flexbox>
				)}
				<InfiniteScroll
					dataLength={parts.length}
					next={() => getMoreProjectParts(match.params.projectId, parts.length)}
					hasMore={projectHasMoreParts}
					loader={
						<Loader
							load={true}
							size={50}
							showFlex={false}
							wrapperClassName="admin-parts--parts-table--loader"
						/>
					}
					height={setMaxHeightForPartsTable ? 1200 : 'unset'}
				>
					<Table
						loading={loading}
						tableHead={tableHeaders}
						tableData={tableContent}
					/>
				</InfiniteScroll>
			</div>
		)
	}

	render() {
		const {
			match,
			projectUpdateLoading,
			loading,
			projectSelected,
			projectHasMoreClusters,
			getMoreProjectClusters,
			onProjectUpdateSubmitClick,
			clusters,
			publishAllClusters,
			publishAllClustersLoading,
			onPublishClusterClick,
			onRejectClusterClick,
			publishClusterLoaders,
			rejectClusterLoaders,
			removeClusterLoaders,
			onRemoveClusterClick,
			onClusterStructureClick,
			clusterStructure,
			projectClusterHeaderText,
			setMaxHeightForClustersTable
		} = this.props
		if (loading) {
			return <Loader load={loading} />
		}

		const editPartProject = Feature.isFeatureOn(FeatureComponentId.EDIT_PROJECT)
		const editPart = Feature.isFeatureOn(FeatureComponentId.EDIT_PART)

		return (
			<NavBarAndMaterial title={getString('PROJECTS')}>
				<div>
					{this.renderAlert()}
					{editPartProject && (
						<CastorForm
							formTitle={getString('EDIT_PROJECT')}
							content={
								<AdminProjectForm
									initialValues={projectSelected}
									onSubmitClick={data =>
										onProjectUpdateSubmitClick(projectSelected.id, data)
									}
									projectUpdateLoading={projectUpdateLoading}
								/>
							}
							style={{ maxWidth: 'unset' }}
						/>
					)}
					<CastorForm
						formTitle={getString('PARTS')}
						formSubTitle={getString('LIST_OF_PARTS')}
						content={this.renderTable(editPart)}
						style={{ maxWidth: 'unset' }}
					/>
					<AdminClusters
						clusters={clusters}
						projectId={match.params.projectId}
						setMaxHeightForClustersTable={setMaxHeightForClustersTable}
						projectHasMoreClusters={projectHasMoreClusters}
						getMoreProjectClusters={getMoreProjectClusters}
						projectClusterHeaderText={projectClusterHeaderText}
						publishAllClusters={() => publishAllClusters(projectSelected.id)}
						publishAllClustersLoading={publishAllClustersLoading}
						onPublishClusterClick={onPublishClusterClick}
						onRejectClusterClick={onRejectClusterClick}
						rejectClusterLoaders={rejectClusterLoaders}
						onRemoveClusterClick={onRemoveClusterClick}
						removeClusterLoaders={removeClusterLoaders}
						publishClusterLoaders={publishClusterLoaders}
						onClusterStructureClick={() =>
							onClusterStructureClick(clusterStructure)
						}
					/>
				</div>
			</NavBarAndMaterial>
		)
	}
}

const styles = {
	textField: { width: '70%', minWidth: 350 },
	smallTextField: { width: 150, marginLeft: 6, marginRight: 6 },
	chart: { height: '300px', marginRight: 20, marginTop: 20, flexGrow: 5 },
	horizontalFlex: { flex: 1, alignItems: 'center' },
	xAxisLabel: { textAlign: 'center' },
	divider: { margin: 10 },
	thumbnail: { width: 150, maxHeight: 150 },
	// card: { marginLeft: 20, marginRight: 20 },
	iconButton: { height: 40, width: 75, alignSelf: 'center' },
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	actionSelect: { flex: 3, width: 300 },
	actionSelectFlex: { alignItems: 'center' },
	runActionButton: { margin: 15 }
}

const mapStateToProps = ({
	user,
	AdminPartsReducer,
	AdminProjectsReducer: { projectSelected, projectUpdateLoading }
}) => {
	return {
		...AdminPartsReducer,
		projectSelected,
		projectUpdateLoading,
		user
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{ ...AdminPartsActions, ...AdminProjectsActions },
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminParts)
