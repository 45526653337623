import { isEmpty } from 'lodash'
import Moment from 'moment'

import {
	ADMIN_PROJECT_DATEPICKER_TOGGLED,
	ADMIN_PROJECT_DOWNLOAD_CLICKED,
	ADMIN_PROJECT_FETCHED,
	ADMIN_PROJECT_FILTER_TYPE_CHANGED,
	ADMIN_PROJECT_GENERATE_REPORT_LOADING,
	ADMIN_PROJECT_SEARCH_PHRASE_CHANGED,
	ADMIN_PROJECT_SELECTED,
	ADMIN_PROJECT_UPDATE_TOGGLED,
	ADMIN_PROJECT_UPDATED,
	ALERT_CANCEL_CLICKED_ADMIN_PROJECTS,
	EXPLODE_PROJECT_SUCCESS,
	GET_ADMIN_PROJECTS,
	GET_ADMIN_PROJECTS_SUCCESS,
	GET_ADMIN_PROJECTS_UNMOUNTED,
	PUBLISH_PROJECT_ADMIN_PAGE,
	PUBLISH_PROJECT_ADMIN_PAGE_SUCCESS,
	PUBLISH_PROJECT_ADMIN_PAGE_SURE_CLICKED,
	SERVER_CALL_FAILED_ADMIN,
	VIEW_PART_LIST_CLICKED,
	VIEW_PROJECT_CLICKED
} from 'global actions/types'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { manufacturingMethodTypes } from 'Services/Constants'
import {
	ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL,
	ADMIN_PROJECTS_CREATED_AT,
	ADMIN_PROJECTS_MATERIAL_OR_BOM,
	ADMIN_PROJECTS_NAME,
	ADMIN_PROJECTS_PARTS_IN_PROJECT,
	ADMIN_PROJECTS_STATUS,
	AM_VALUE_TEXT,
	ASSEMBLY,
	BOM,
	ID,
	MANUFACTURING_METHOD_TEXT,
	MULTI_MATERIAL,
	OWNER_ID,
	OWNER_NAME,
	PRODUCT_LIFE_CYCLE,
	PROJECT_UNIT_TYPE,
	STANDARD_COST,
	UPLOAD_INDIVIDUAL_PARTS_SELECTION,
	UPLOAD_PROJECT_APPLICATION,
	UPLOAD_PROJECT_YEARLY_PROD
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const empty = '-'

const INITIAL_STATE = {
	loading: false,
	error: false,
	projects: [],
	showingSimpleAlertTitle: null,
	showingSimpleAlertText: null,
	projectWaitingForPublishing: null,
	userProjectsName: '',
	isLastPage: false,
	totalProjects: 0,
	pageNumber: 1,
	NumOfProjectsSeen: 0,
	limitProjects: 10,
	projectsOwnerId: '',
	showPagination: true,
	projectSelected: null,
	projectInfoData: [],
	projectDownloadUrlLoading: {},
	projectUpdateLoading: false,
	searchPhrase: '',
	filterType: [],
	showNoProjectsAlert: false,
	showAdminProjectsAlert: false,
	showExportExcel: true,
	isAdminProjectReportLoading: false
}

const adminProjectsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_ADMIN_PROJECTS:
			return { ...state, loading: true }
		case SERVER_CALL_FAILED_ADMIN:
			return {
				...state,
				loading: false,
				showAdminProjectsAlert: true,
				error: true
			}
		case ADMIN_PROJECT_DATEPICKER_TOGGLED:
			return {
				...state,
				showDatepicker: action.payload
			}
		case GET_ADMIN_PROJECTS_SUCCESS: {
			const {
				data: { projects, totalProjects },
				userId,
				pageNumber,
				searchPhrase
			} = action.payload
			const NumOfProjectsSeen =
					(pageNumber - 1) * state.limitProjects + projects.length,
				userProjectsName = userId && projects[0] ? projects[0].owner.name : '',
				isLastPage = NumOfProjectsSeen >= totalProjects

			return {
				...state,
				searchPhrase,
				loading: false,
				projects,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null,
				isLastPage,
				userProjectsName,
				pageNumber,
				NumOfProjectsSeen,
				totalProjects,
				showPagination: !(isLastPage && pageNumber === 1),
				projectsOwnerId: userId,
				showNoProjectsAlert: !projects.length,
				showAdminProjectsAlert: false,
				error: false
			}
		}
		case PUBLISH_PROJECT_ADMIN_PAGE:
			const projectWaitingForPublishing = action.payload
			const showingSimpleAlertTitle = `Publish '${projectWaitingForPublishing.name}'?`
			const showingSimpleAlertText = `This will send the user an email notification and allow the user to view the project.`
			return {
				...state,
				showingSimpleAlertTitle,
				showingSimpleAlertText,
				projectWaitingForPublishing
			}
		case PUBLISH_PROJECT_ADMIN_PAGE_SURE_CLICKED:
			return {
				...state,
				loading: true,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null
			}
		case ALERT_CANCEL_CLICKED_ADMIN_PROJECTS:
			return {
				...state,
				loading: false,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null,
				projectWaitingForPublishing: null
			}

		case PUBLISH_PROJECT_ADMIN_PAGE_SUCCESS:
			state.projectWaitingForPublishing.status = 'published'
			return {
				...state,
				loading: false,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null,
				projectWaitingForPublishing: null
			}

		case ADMIN_PROJECT_SELECTED:
			const { project } = action.payload
			return {
				...state,
				projectSelected: {
					id: project.id,
					name: project.name,
					owner: project.owner.id
				},
				projectInfoData: createProjectDataInfo(project)
			}

		case GET_ADMIN_PROJECTS_UNMOUNTED:
			return {
				...state,
				searchPhrase: ''
			}

		case ADMIN_PROJECT_FETCHED: {
			const { project } = action.payload
			return {
				...state,
				projectSelected: {
					id: project.id,
					name: project.name,
					owner: project.owner.id
				},
				loading: false,
				projectInfoData: createProjectDataInfo(project)
			}
		}
		case EXPLODE_PROJECT_SUCCESS: {
			const { updateProject } = action.payload
			return {
				...state,
				projects: state.projects.map(project =>
					project.id === updateProject.id ? updateProject : project
				)
			}
		}
		case ADMIN_PROJECT_DOWNLOAD_CLICKED: {
			const { projectId } = action.payload
			return {
				...state,
				projectDownloadUrlLoading: {
					...state.projectDownloadUrlLoading,
					[projectId]: !state.projectDownloadUrlLoading[projectId]
				}
			}
		}

		case ADMIN_PROJECT_UPDATE_TOGGLED:
			return { ...state, projectUpdateLoading: !state.projectUpdateLoading }

		case ADMIN_PROJECT_UPDATED:
			const { projectUpdated } = action.payload
			return {
				...state,
				projects: state.projects.map(project =>
					project.id === projectUpdated.id ? projectUpdated : project
				),
				projectUpdateLoading: false
			}
		case ADMIN_PROJECT_SEARCH_PHRASE_CHANGED:
			const searchPhrase = action.payload
			return {
				...state,
				searchPhrase,
				showExportExcel: !(state.filterType?.length || searchPhrase)
			}

		case ADMIN_PROJECT_FILTER_TYPE_CHANGED:
			const filterType = action.payload
			return {
				...state,
				filterType,
				showExportExcel: !(filterType?.length || state.searchPhrase)
			}

		case ADMIN_PROJECT_GENERATE_REPORT_LOADING:
			return {
				...state,
				isAdminProjectReportLoading: action.payload
			}
		case VIEW_PROJECT_CLICKED:
		case VIEW_PART_LIST_CLICKED:
		default:
			return state
	}
}

const createProjectDataInfo = project => {
	const dataTableService = new DataTableService()
	const amProjectValue = project.amValue && JSON.parse(project.amValue)
	const defaultAmType = getString('AM_TYPE_IMPROVEMENTS')

	const productLifeCycle =
		getString('UPLOAD_PROJECT_SCENARIO_METHODS_HEADER')[
			project.productLifeCycle
		] || empty
	const manufactureMethod =
		manufacturingMethodTypes[project.manufactureMethod] || empty
	const amValue = isEmpty(amProjectValue)
		? empty
		: amProjectValue.map(el => defaultAmType[el]).join(', ')

	return [
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: ADMIN_PROJECTS_NAME,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.name,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: ID,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.id,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: OWNER_NAME,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.owner.name,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: OWNER_ID,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.owner.id,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: ADMIN_PROJECTS_STATUS,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.status,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: ADMIN_PROJECTS_CREATED_AT,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: Moment(project.createdAt).format('LLLL'),
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: UPLOAD_PROJECT_YEARLY_PROD,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.quantity,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: UPLOAD_PROJECT_APPLICATION,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.application,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: ADMIN_PROJECTS_MATERIAL_OR_BOM,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text:
					project.defaultMaterialId && !project.bomFileURL
						? project.defaultMaterial?.name || project.defaultMaterial
						: project.bomFileURL
						? BOM
						: MULTI_MATERIAL,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.isAssembly ? ASSEMBLY : UPLOAD_INDIVIDUAL_PARTS_SELECTION,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: PROJECT_UNIT_TYPE,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.unitType,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: ADMIN_PROJECTS_PARTS_IN_PROJECT,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.partCount,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: MANUFACTURING_METHOD_TEXT,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: manufactureMethod,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: AM_VALUE_TEXT,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: amValue,
				className: 'admin--projects--project-info--field full-width'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: PRODUCT_LIFE_CYCLE,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: productLifeCycle,
				className: 'admin--projects--project-info--field'
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: STANDARD_COST,
				className:
					'admin--projects--project-info--field admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: project.projectStandardCost || empty,
				className: 'admin--projects--project-info--field'
			})
		]
	]
}

export default adminProjectsReducer
