import React, { FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Fade } from 'react-reveal'
import { reset } from 'redux-form'

import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import * as SolutionAnalysisActions from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import Loader from '../../../../Loader/Loader'
import {
	formatComponent,
	onCalculateClick,
	onConfigureBackClick,
	onFilterModalCancel,
	onFilterModalConfirm,
	onResetClick,
	onSimpleInhouseConfigurationChange,
	setupSolution
} from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import FilterFeaturesModal from '../../MainPartAnalysis/SolutionAnalysis/SolutionFilterFeature'
import { addNewConfigurationId } from '../PartAnalysisConstants'
import {
	usePartReducer,
	useSolutionReducer,
	useUserReducer
} from '../PartAnalysisSelector'
import ConfigurationCreateNew from './ConfigurationCreateNew'
import ConfigurationCreatePrinterMaterial from './ConfigurationCreatePrinterMaterial'
import { ActionWithPayload } from 'global actions/ActionModels'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { prepareFiltersToSend } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { materialTypeChanged } from 'Scenes/Components/MaterialSelector/TmMaterialSelector/MaterialSelectorActions'
import { ISimpleConfigurationCompany } from 'Scenes/Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { onInHousePrintersAlertOpen } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import { hideInhouseAlertWithMaterial } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/MainPartAnalysisActions'
import { defaultMetal, materialTypes } from 'Services/Constants'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { IConfigurationCustomizationSettings } from 'Services/models/IConfigurationCustomizationSettings'
import { FormatType } from 'Services/models/IPart'
import { IPriority } from 'Services/models/IPriority'
import { IUserFilterNames } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

interface ConfigurationNewSolutionAlertProps {
	setShowNewConfigurationAlert: Function
	showNewConfigurationAlert: boolean
	configuration: any
	isAmOriginalMaterial: boolean
	subCategoryData: any
}

let ConfigurationNewAlert: FC<ConfigurationNewSolutionAlertProps> = ({
	setShowNewConfigurationAlert,
	showNewConfigurationAlert,
	configuration,
	isAmOriginalMaterial,
	subCategoryData
}) => {
	const dispatch = useDispatch()
	const configurationId = configuration.id
	const {
		disableCalculateButton,
		loadingCalculation,
		solutionPriorities,
		simpleConfigurationSelectorPrinterValue,
		simpleConfigurationSelectorMaterialValue,
		simpleInhouseConfiguration,
		tempSolutionPostProcessToggles,
		solution,
		printCostQuantity,
		printingOrientationVector,
		printingOrientationCalc,
		simpleConfiguration,
		initialMaterial,
		originalPostProcessValues,
		printerMaterialID,
		chosenMaterial,
		keepMaterialForInHouseClick,
		showFilterFeaturesModal,
		doSetup,
		calculateWithOrganizationId
	} = useSolutionReducer(configurationId)
	const {
		initialBatchSize,
		partId,
		project,
		clusterId,
		tourConfigurationId,
		part,
		cluster,
		partMaterial,
		solutions,
		isWeightReductionPart,
		partFeas,
		partPrintIssues,
		disablePart,
		feaId,
		allConfigurationsOrganizationSettings
	} = usePartReducer()
	const {
		priorities,
		printersFullData: userPrintersFullData,
		allOptionalPostProcessesData: userAllOptionalPostProcessesData,
		userCurrencySign,
		optionalPostProcessAvailability: userOptionalPostProcessAvailability,
		optionalPostProcessesBreakDown,
		materialCategories,
		materials: userMaterials,
		printers: userPrinters,
		printerMaterials: userPrinterMaterials,
		printerMaterialsSubCategories: userPrinterMaterialsSubCategories,
		printerMaterialsCategories: userPrinterMaterialsCategories
	} = useUserReducer()
	const { filters } = useSelector((state: RootStateOrAny) => {
		return (
			state.AdvancedSettingsReducer?.advancedStates[configurationId] ||
			new AdvancedSettingsInitialState()
		)
	})
	const [quantity, setQuantity] = useState<number>(initialBatchSize)
	const [configurationName, setConfigurationName] = useState<string>('')
	const [loading, setLoading] = useState(loadingCalculation)
	const [showReset, setShowReset] = useState(true)

	const configurationOrganizationSettings: IConfigurationCustomizationSettings =
		allConfigurationsOrganizationSettings[calculateWithOrganizationId] || {}
	const printersFullData =
		configurationOrganizationSettings.printersFullData || userPrintersFullData
	const allOptionalPostProcessesData =
		configurationOrganizationSettings.allOptionalPostProcessesData ||
		userAllOptionalPostProcessesData
	const optionalPostProcessAvailability =
		configurationOrganizationSettings.optionalPostProcessAvailability ||
		userOptionalPostProcessAvailability
	const materials = configurationOrganizationSettings.materials || userMaterials
	const printers = configurationOrganizationSettings.printers || userPrinters
	const printerMaterials =
		configurationOrganizationSettings.printerMaterials || userPrinterMaterials
	const printerMaterialsSubCategories =
		configurationOrganizationSettings.printerMaterialsSubCategories ||
		userPrinterMaterialsSubCategories
	const printerMaterialsCategories =
		configurationOrganizationSettings.printerMaterialsCategories ||
		userPrinterMaterialsCategories

	const toleranceIncluded = project && project.toleranceIncluded
	const defaultSelectedMaterial =
		configuration.material === materialTypes.metal
			? defaultMetal
			: defaultMaterial
	const selectedMaterial = !isEmpty(chosenMaterial)
		? chosenMaterial
		: !isEmpty(partMaterial)
		? partMaterial
		: defaultSelectedMaterial

	const willCreateNewConfiguration = configurationId === addNewConfigurationId
	const prevShowNewConfigurationAlert = usePrevious(showNewConfigurationAlert)

	useEffect(() => {
		if (!loadingCalculation && loadingCalculation !== loading) {
			setShowNewConfigurationAlert(false)
		}
		setLoading(loadingCalculation)
		setShowReset(!loadingCalculation)
	}, [loadingCalculation])

	useEffect(() => {
		if (
			prevShowNewConfigurationAlert !== showNewConfigurationAlert ||
			doSetup
		) {
			dispatch(
				setupSolution(
					configuration,
					part,
					cluster,
					filters,
					priorities,
					initialBatchSize,
					partMaterial,
					materials,
					solutions.find((s: any) => s.id === configuration?.solution?.id),
					allOptionalPostProcessesData,
					optionalPostProcessAvailability,
					optionalPostProcessesBreakDown,
					toleranceIncluded,
					userCurrencySign,
					printersFullData as ISimpleConfigurationCompany[],
					isWeightReductionPart,
					partFeas.find((pF: any) => pF.configurationId === configuration?.id),
					partPrintIssues,
					materialCategories,
					disablePart,
					printerMaterials,
					printerMaterialsSubCategories,
					printerMaterialsCategories,
					feaId
				)
			)
		}

		if (
			prevShowNewConfigurationAlert !== showNewConfigurationAlert &&
			!showNewConfigurationAlert
		) {
			return () => resetNewConfiguration(keepMaterialForInHouseClick)
		}
	}, [showNewConfigurationAlert, doSetup])

	const onResetClicked = (
		onResetClick: (
			...onResetClickParams: Parameters<
				typeof SolutionAnalysisActions.onResetClick
			>
		) => ActionWithPayload<any>,
		reset: any,
		configuration: any,
		priorities: IPriority[],
		initialMaterial: any,
		materials: any[],
		originalPostProcessValues: any,
		solution: any,
		project: any,
		materialCategories: any[]
	) => {
		dispatch(
			onResetClick(
				configurationId,
				configuration,
				priorities,
				initialMaterial,
				materials,
				originalPostProcessValues,
				solution,
				project,
				materialCategories
			)
		)
	}

	const resetNewConfiguration = (keepMaterial: boolean = false) => {
		// we need it only for reset from unmount
		if (keepMaterial) return

		setQuantity(initialBatchSize)
		setConfigurationName('')
		dispatch(materialTypeChanged(defaultSelectedMaterial.type))
		dispatch(setupAdvancedFilters(configurationId, true))

		if (simpleConfiguration) {
			dispatch(
				onSimpleInhouseConfigurationChange(
					configurationId,
					printersFullData as ISimpleConfigurationCompany[],
					printers,
					configuration,
					false
				)
			)
		}

		onResetClicked(
			onResetClick,
			reset,
			configuration,
			priorities,
			initialMaterial,
			materials,
			originalPostProcessValues,
			solution,
			project,
			materialCategories
		)
	}

	const confirmNewConfiguration = () => {
		let printerMaterialFilters: any = prepareFiltersToSend(filters)
		let material = selectedMaterial
		let solutionName = configurationName

		if (simpleConfiguration) {
			printerMaterialFilters = {
				...printerMaterialFilters,
				inHousePrinters: simpleInhouseConfiguration,
				printerMaterialID: printerMaterialID
			}

			material = initialMaterial

			solutionName = isEmpty(configurationName)
				? simpleConfigurationSelectorPrinterValue.name
				: configurationName
		}
		dispatch(hideInhouseAlertWithMaterial(configurationId, false))
		dispatch(
			onCalculateClick(
				configurationId,
				solution,
				partId,
				project.id,
				Object.fromEntries(solutionPriorities),
				printerMaterialFilters,
				null,
				solutionName,
				configuration.solution && configuration.solution.id,
				material,
				subCategoryData,
				quantity,
				tempSolutionPostProcessToggles,
				allOptionalPostProcessesData,
				optionalPostProcessesBreakDown,
				optionalPostProcessAvailability,
				toleranceIncluded,
				clusterId,
				false,
				part || cluster,
				userCurrencySign,
				printCostQuantity,
				isAmOriginalMaterial,
				printingOrientationVector,
				printingOrientationCalc,
				false,
				simpleConfiguration,
				simpleConfigurationSelectorPrinterValue,
				simpleConfigurationSelectorMaterialValue,
				simpleInhouseConfiguration,
				undefined,
				tourConfigurationId,
				undefined,
				calculateWithOrganizationId
			)
		)
	}

	const handleAlertConfirm = () => {
		const inHouseOn = filters.find(
			(filter: any) => filter.name === IUserFilterNames.inHousePrinters
		)?.checked
		if (willCreateNewConfiguration && inHouseOn && !printers?.length) {
			dispatch(
				onInHousePrintersAlertOpen(
					calculateWithOrganizationId,
					configurationId.toString()
				)
			)
			return
		}
		confirmNewConfiguration()
	}

	return (
		<>
			<CastorAlert
				onConfirmReset={() => resetNewConfiguration()}
				showReset={showReset}
				headerTitle={
					configurationId === addNewConfigurationId
						? getString('NEW_CONFIGURATION')
						: getString('ADD_PRINTER_MATERIAL_CONFIGURATION_BUTTON')
				}
				alertClass={cx('add-new-solution-alert', {
					loading: loadingCalculation
				})}
				fullScreen={true}
				confirmOptionalText={getString('CALCULATE_CONFIGURATION')}
				onConfirm={handleAlertConfirm}
				onCancel={() => {
					setShowNewConfigurationAlert(false)
					dispatch(formatComponent(configurationId))
					dispatch(onConfigureBackClick(configurationId))
				}}
				show={showNewConfigurationAlert}
				disabled={disableCalculateButton}
				loadingCalculation={loadingCalculation}
			>
				<Loader
					wrapperClassName="new-configuration-loading"
					message={getString('CALCULATING')}
					load={loadingCalculation}
				/>
				<Fade>
					{willCreateNewConfiguration ? (
						<ConfigurationCreateNew
							filters={filters}
							defaultSelectedMaterial={selectedMaterial}
							setQuantity={setQuantity}
							quantity={quantity}
							configurationName={configurationName}
							setConfigurationName={setConfigurationName}
							configuration={configuration}
							isPart2d={(part || cluster)?.formatType === FormatType.pdf}
							isSpecifiedQuantity={part?.isSpecifiedQuantity}
							isAmOriginalMaterial={isAmOriginalMaterial}
						/>
					) : (
						<ConfigurationCreatePrinterMaterial
							setQuantity={setQuantity}
							quantity={quantity}
							configurationName={configurationName}
							setConfigurationName={setConfigurationName}
							configuration={configuration}
							isPart2d={(part || cluster)?.formatType === FormatType.pdf}
							isSpecifiedQuantity={part?.isSpecifiedQuantity}
						/>
					)}
				</Fade>
			</CastorAlert>
			<FilterFeaturesModal
				showFilterFeaturesModal={showFilterFeaturesModal}
				onFilterModalCancel={(configuration: any) =>
					dispatch(onFilterModalCancel(configuration))
				}
				onFilterModalConfirm={(id: any, toggles: any) =>
					dispatch(onFilterModalConfirm(id, toggles))
				}
				configuration={configuration}
			/>
		</>
	)
}

export default memo(ConfigurationNewAlert)
