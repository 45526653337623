import { isEmpty, isString, partition, some } from 'lodash'

import {
	getWeightReducedValue,
	getWeightReductionRate,
	getWeightReductionRequestText,
	isPartLegalWeightReducedSuggestion
} from '../../Components/WeightReduction/WeightReductionService'
import { getIssues } from '../NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisService'
import {
	BenefitNameType,
	benefitsAnalysisType,
	chartNames,
	IBenefitStateType,
	IPartsToPrintSummary,
	PartFailedIssueWithMessage,
	PartResultName,
	ValueOf
} from './ProjectAnalysisInterfaces'
import {
	INAPPLICABLE,
	InapplicableStatus,
	materialTypes,
	partResults,
	partsPageLimit,
	printStatusScore
} from 'Services/Constants'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Part, PartStatus } from 'Services/models/IPart'
import {
	Project,
	ProjectClusterStatus,
	ProjectWeightReductionStatus
} from 'Services/models/IProject'
import { benefitType, ISolutionBenefit } from 'Services/models/ISolutionBenefit'
import { IUser, Locale } from 'Services/models/IUser'
import {
	PartPrintIssue,
	PrintIssue,
	PrintIssueId
} from 'Services/models/PartPrintIssue'
import {
	checkResultsIssues,
	getMaterialScore,
	getResultScore,
	getSizeScore
} from 'Services/PrintIssueService'
import { partConfigurationsRoute } from 'Services/routeFuncs'
import {
	DOWNLOAD,
	PART,
	PART_RESULTS_OPTIONS,
	REDUCE_WEIGHT_BUTTON_TEXT
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getUserLanguage } from 'Services/Utils/startupTools'

export const checkLockedPartsNumber = (
	amountOfLockedParts: string | number
) => {
	if (typeof amountOfLockedParts === 'number') {
		if (amountOfLockedParts === 0) {
			return ''
		}
		return amountOfLockedParts.toString()
	}
	if (!amountOfLockedParts) {
		return ''
	}
}

export const getClusterRequestText = (
	clusterStatus: string,
	clustersNumber?: number
) => {
	switch (clusterStatus) {
		case ProjectClusterStatus.noClustersFound:
			return getString('COMBINE_MULTIPLE_INTO_ONE_CARD_NO_CLUSTERS_TXT')
		case ProjectClusterStatus.awaitingAnalysis:
			return getString('COMBINE_MULTIPLE_INTO_ONE_CARD_REQESTED_TXT')
		case ProjectClusterStatus.failed:
			return getString('COMBINE_MULTIPLE_INTO_ONE_CARD_FAILED_TXT')
		case ProjectClusterStatus.complete: {
			return
			// TODO: pass correct clustersNumber from response
			// return getString('COMBINE_MULTIPLE_INTO_ONE_CARD_COMPLETED_TXT').format(
			//   clustersNumber
			// )
		}

		default:
			return getString('COMBINE_MULTIPLE_INTO_ONE_CARD_NO_CLUSTERS_TXT') // By default NO clusters found
	}
}

export const getProjectClusterStatus = (
	project: any,
	showMultiplePartsIntoOneResults?: boolean
) => {
	let projectClusterStatus = project?.clusterStatus

	if (
		!projectClusterStatus &&
		project?.clusters &&
		showMultiplePartsIntoOneResults
	) {
		return ProjectClusterStatus.complete
	}
	return projectClusterStatus
}

export const getOnlyPartState = (
	isSinglePartProject: boolean,
	onlyPartFromServer: Part,
	state: any
) => {
	if (!isSinglePartProject) {
		return {}
	}
	let onlyPart = isEmpty(onlyPartFromServer)
		? state.onlyPart
		: onlyPartFromServer
	let onlyPartResultTitle = state.onlyPartResultTitle

	if (onlyPart.isDrawing && onlyPart.drawingStatusCode === InapplicableStatus) {
		onlyPartResultTitle = getPartResultTitle(INAPPLICABLE)
	} else {
		onlyPartResultTitle = getPartResultTitle(onlyPart.result)
	}

	return {
		onlyPart,
		onlyPartResultTitle
	}
}

export const getPartsWithBenefit = (
	benefitType: benefitType,
	printableParts: Part[]
) => {
	return printableParts.filter(
		(part: Part) =>
			!!part.benefits?.find(
				(benefit: ISolutionBenefit) => benefit.type === benefitType
			)
	).length
}

export const getPartsWithWeightReduction = (printableParts: Part[]) => {
	return printableParts.filter((part: Part) =>
		isPartLegalWeightReducedSuggestion(part)
	).length
}

export const getAllBenefitsState = (
	numberOfPrintableParts: number,
	numberOfWeightReductionOpportunities: number,
	benefitsData: Record<ValueOf<typeof benefitType>, number>,
	project: any,
	features: any[],
	is2dProject: boolean
):
	| {
			benefitsState: IBenefitStateType[]
			numberOfPrintableParts: number
			numberOfAllBenefits: number
	  }
	| {} => {
	if (!benefitsData) {
		return {}
	}

	const numberOfCostSavingParts = benefitsData[benefitType.costSaving]
	const numberOfTimeSavingParts = benefitsData[benefitType.timeSaving]
	const numberOfBuyToFlyParts = benefitsData[benefitType.buyToFly]
	const numberOfComplexityScoreParts = benefitsData[benefitType.complexityScore]

	const benefitsState = [
		{
			name: benefitsAnalysisType.costSaving,
			numberOfParts: numberOfCostSavingParts,
			printableChartData: createChartData(
				numberOfPrintableParts,
				numberOfCostSavingParts,
				benefitsAnalysisType.costSaving
			)
		},
		{
			name: benefitsAnalysisType.timeSaving,
			numberOfParts: numberOfTimeSavingParts,
			printableChartData: createChartData(
				numberOfPrintableParts,
				numberOfTimeSavingParts,
				benefitsAnalysisType.timeSaving
			)
		}
	]

	if (Feature.isFeatureOn(FeatureComponentId.BUY_TO_FLY, features)) {
		benefitsState.push({
			name: benefitsAnalysisType.buyToFly,
			numberOfParts: numberOfBuyToFlyParts,
			printableChartData: createChartData(
				numberOfPrintableParts,
				numberOfBuyToFlyParts,
				benefitsAnalysisType.buyToFly
			)
		})
	}

	if (Feature.isFeatureOn(FeatureComponentId.PART_COMPLEXITY_SCORE, features)) {
		benefitsState.push({
			name: benefitsAnalysisType.complexityScore,
			numberOfParts: numberOfComplexityScoreParts,
			printableChartData: createChartData(
				numberOfPrintableParts,
				numberOfComplexityScoreParts,
				benefitsAnalysisType.complexityScore
			)
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION, features) &&
		!is2dProject
	) {
		benefitsState.push({
			name: benefitsAnalysisType.weightReduction,
			numberOfParts: numberOfWeightReductionOpportunities,
			printableChartData: createChartData(
				numberOfPrintableParts,
				numberOfWeightReductionOpportunities,
				benefitsAnalysisType.weightReduction
			)
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.UNIFICATION, features) &&
		!is2dProject
	) {
		benefitsState.push({
			name: benefitsAnalysisType.partConsolidation,
			numberOfParts: project?.clusters,
			printableChartData: createChartData(
				numberOfPrintableParts,
				project?.clusters,
				benefitsAnalysisType.partConsolidation
			)
		})
	}

	const numberOfAllBenefits = benefitsState.reduce((acc, benefit) => {
		if (benefit.numberOfParts > 0) acc++
		return acc
	}, 0)

	return { numberOfPrintableParts, numberOfAllBenefits, benefitsState }
}

export const getClustersStatuses = (
	projects: Project[],
	clustersCount: number
) => {
	const failedStatus = some(
		projects,
		project => project.clusterStatus === ProjectClusterStatus.failed
	)
	const awaitingStatus = some(
		projects,
		project => project.clusterStatus === ProjectClusterStatus.awaitingAnalysis
	)
	const awaitingRequestStatus = some(
		projects,
		project => project.clusterStatus === ProjectClusterStatus.awaitingRequest
	)

	return failedStatus
		? ProjectClusterStatus.failed
		: awaitingStatus
		? ProjectClusterStatus.awaitingAnalysis
		: awaitingRequestStatus
		? ProjectClusterStatus.awaitingRequest
		: clustersCount < 1
		? ProjectClusterStatus.noClustersFound
		: ProjectClusterStatus.complete
}

export const getWightReductionStatuses = (projects: Project[]) => {
	const failedStatus = some(
		projects,
		project =>
			project.weightReductionStatus === ProjectWeightReductionStatus.failed
	)
	const awaitingStatus = some(
		projects,
		project =>
			project.weightReductionStatus ===
			ProjectWeightReductionStatus.awaitingAnalysis
	)

	return failedStatus
		? ProjectWeightReductionStatus.failed
		: awaitingStatus
		? ProjectWeightReductionStatus.awaitingAnalysis
		: ProjectWeightReductionStatus.complete
}

export const getClusterState = (project: any) => {
	const showMultiplePartsIntoOneResults =
		project?.clusters && project.clusters > 0

	const projectClusterStatus = getProjectClusterStatus(
		project,
		showMultiplePartsIntoOneResults
	)

	const clusterRequested =
		projectClusterStatus != null || showMultiplePartsIntoOneResults

	const clusterRequestText = getClusterRequestText(
		projectClusterStatus,
		project?.clusters
	)

	const showMultiplePartsIntoOneRequest = project.offerMultiplePartsIntoOne

	return {
		showMultiplePartsIntoOneRequest,
		showMultiplePartsIntoOneResults,
		clusterRequestText,
		clusterRequested,
		projectClusterStatus
	}
}

export const getWeightReductionStatus = (
	project: any,
	partsWeightReductionNumber: number,
	allPartsNotCostEffective: boolean,
	userId: number
) => {
	let projectWeightReductionStatus = project?.weightReductionStatus

	if (
		projectWeightReductionStatus === ProjectWeightReductionStatus.failed &&
		project.forcePublished
	) {
		return ProjectWeightReductionStatus.failed
	}

	if (
		((projectWeightReductionStatus != null ||
			(projectWeightReductionStatus === null && allPartsNotCostEffective)) &&
			projectWeightReductionStatus !==
				ProjectWeightReductionStatus.awaitingAnalysis) ||
		(projectWeightReductionStatus === null && project.ownerId !== userId)
	) {
		if (!partsWeightReductionNumber) {
			return ProjectWeightReductionStatus.noWeightReductionFound
		} else {
			return ProjectWeightReductionStatus.complete
		}
	}
	return projectWeightReductionStatus
}

export const getWeightReductionState = (
	weightReductionParts: any,
	project: any,
	features: any,
	allPartsNotCostEffective: boolean,
	userId: number,
	totalWeightReduction?: number
) => {
	if (!weightReductionParts) {
		return {}
	}
	let partsWeightReductionNumber = 0
	const weightReductionItemsWithPictures: any = []
	const weightReducedSuggestedParts: any = []
	let isUrlEmpry = false
	if (!Feature.isFeatureActive(FeatureComponentId.WEIGHT_REDUCTION, features)) {
		isUrlEmpry = true
	}

	weightReductionParts.forEach((part: Part) => {
		let subTitleLink = {
			url: part.weightReducedStlURL,
			text: REDUCE_WEIGHT_BUTTON_TEXT
			//TODO: Lidor - add here the onclick event
			// onClick: buttonClickFunction(id, stepURL, hasBrepData)
		}

		if (
			Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION_OLD, features)
		) {
			subTitleLink = {
				url: isUrlEmpry ? '' : part.weightReducedStlURL,
				text: DOWNLOAD
			}
		}

		if (isPartLegalWeightReducedSuggestion(part)) {
			partsWeightReductionNumber++
			weightReducedSuggestedParts.push(part)
			weightReductionItemsWithPictures.push({
				id: part.id,
				image: part.imageURL,
				title: part.partNumber,
				stepURL: part.stepURL,
				linkTo: {
					pathname: partConfigurationsRoute(project?.id, part.id),
					state: { isWeightReduction: true }
				},
				subTitle: getString('WEIGHT_REDUCED_PART').format(
					getWeightReductionRate(part.volume, part.weightReducedVolume),
					getWeightReducedValue(
						part.volume,
						part.weightReducedVolume,
						part.weightReductionDensity
					)
				),
				subTitleLink,
				hasBrepData: part?.hasBrepData,
				isWeightReductionLoading: part?.isWeightReductionLoading || false
			})
		}
	})

	const projectWeightReductionStatus = getWeightReductionStatus(
		project,
		partsWeightReductionNumber,
		allPartsNotCostEffective,
		userId
	)

	const weightReductionRequested =
		projectWeightReductionStatus != null &&
		projectWeightReductionStatus !== ProjectWeightReductionStatus.failed

	const weightReductionRequestText = getWeightReductionRequestText(
		projectWeightReductionStatus,
		totalWeightReduction || partsWeightReductionNumber
	)

	return {
		projectWeightReductionStatus,
		weightReductionRequested,
		weightReductionRequestText,
		weightReductionItemsWithPictures,
		weightReducedSuggestedParts
	}
}

export const getConcatWeightReductionState = (
	state: any,
	weightReductionParts: any,
	features: any
) => {
	if (!weightReductionParts) {
		return {}
	}
	const weightReductionItemsWithPictures: any = []
	const weightReducedSuggestedParts: any = []
	let isUrlEmpry = false
	if (!Feature.isFeatureActive(FeatureComponentId.WEIGHT_REDUCTION, features)) {
		isUrlEmpry = true
	}

	weightReductionParts.forEach((part: Part) => {
		let subTitleLink = {
			url: part.weightReducedStlURL,
			text: REDUCE_WEIGHT_BUTTON_TEXT
			// onClick: buttonClickFunction(id, stepURL, hasBrepData)
		}

		if (
			Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION_OLD, features)
		) {
			subTitleLink = {
				url: isUrlEmpry ? '' : part.weightReducedStlURL,
				text: DOWNLOAD
			}
		}

		if (isPartLegalWeightReducedSuggestion(part)) {
			weightReducedSuggestedParts.push(part)
			weightReductionItemsWithPictures.push({
				id: part.id,
				image: part.imageURL,
				title: part.partNumber,
				stepURL: part.stepURL,
				linkTo: {
					pathname: partConfigurationsRoute(state?.project?.id, part.id),
					state: { isWeightReduction: true }
				},
				subTitle: getString('WEIGHT_REDUCED_PART').format(
					getWeightReductionRate(part.volume, part.weightReducedVolume),
					getWeightReducedValue(
						part.volume,
						part.weightReducedVolume,
						part.weightReductionDensity
					)
				),
				subTitleLink,
				hasBrepData: part?.hasBrepData,
				isWeightReductionLoading: part?.isWeightReductionLoading || false
			})
		}
	})

	return {
		weightReductionItemsWithPictures:
			state.weightReductionItemsWithPictures.concat(
				weightReductionItemsWithPictures
			),
		weightReducedSuggestedParts: state.weightReducedSuggestedParts.concat(
			weightReducedSuggestedParts
		)
	}
}

export const getPartsStandardCosts = (
	partsWithStandardCost: any,
	totalPartsLength: number
) => {
	if (!partsWithStandardCost) {
		return {}
	}
	const partsStandardCosts = partsWithStandardCost
		.filter(
			(part: Part) =>
				part.result !== partResults.notCostEffective &&
				part.status !== PartStatus.awaitingCombinedHeatmap
		)
		.map((part: Part) => [
			{ value: part.partNumber, valid: true },
			{ value: part.standardCost, valid: true }
		])

	const emptyRowsCount =
		totalPartsLength + (totalPartsLength - partsWithStandardCost.length)
	const emptyRow = [
		{ value: '', valid: true },
		{ value: '', valid: true }
	]
	const emptyRows = new Array(emptyRowsCount).fill(emptyRow)

	return [...partsStandardCosts, ...emptyRows]
}

export const getPartResultTitle = (result: string) => {
	switch (result) {
		case partResults.printable:
			return getString('PART_RESULT_TITLE_PRINTABLE')
		case partResults.borderline:
			return getString('PART_RESULT_TITLE_BORDERLINE')
		case partResults.notPrintable:
			return getString('PART_RESULT_TITLE_NOT_PRINTABLE')
		case partResults.notCostEffective:
			return getString('PART_RESULT_TITLE_NOT_COST_EFFECTIVE')
		case partResults.failed || partResults.dependencyAnalysisFailed:
			return getString('PART_RESULT_TITLE_FAILED')
		case INAPPLICABLE:
			return getString('PART_RESULT_TITLE_INAPPLICABLE')

		default:
			return ''
	}
}

export const findBenefitTitle = (benefit: BenefitNameType): string =>
	PART_RESULTS_OPTIONS.find(
		(option: { title: string; value: string }) =>
			option.value === chartNames[benefit]
	)?.title || ''

export const calcultePercent = (count: number, total: number) =>
	parseInt(((100 * count) / total).toFixed())

export const getPartValue = (value?: string | number) =>
	isString(value) ? value.trim() : value ? value : ''

export const countToPieChartLabel = (
	percent: number,
	numberOfParts: number,
	name?: BenefitNameType,
	isEnLocale?: boolean
) => {
	if (name) {
		let benefitParts
		const title = findBenefitTitle(name).toLowerCase()
		if (
			name === benefitsAnalysisType.weightReduction ||
			name === benefitsAnalysisType.partConsolidation
		) {
			benefitParts = isEnLocale ? title.replace(/.$/, 'ies') : `${title}\n`
		} else {
			benefitParts = `${getString('PART_S').format(title)}${
				isEnLocale ? 's' : '\n'
			}`
		}
		const label = getString('NO_BENEFIT_PARTS').format(benefitParts)
		return percent <= 0 ? ' ' : percent === 100 ? label : numberOfParts
	}
	return numberOfParts || ' '
}

export const countToPieChartLongLabel = (
	count: number,
	percent: number,
	name?: BenefitNameType,
	isEnLocale?: boolean
): string => {
	if (count === 0) return ' '
	if (name === benefitsAnalysisType.partConsolidation) {
		const benefitTitle = findBenefitTitle(name)
		const title = benefitTitle.toLowerCase().split(' ').at(-1) || ' '
		const pluralTitle = title.replace(/.$/, 'ies')
		return `${count} ${
			isEnLocale ? (count === 1 ? title : pluralTitle) : benefitTitle
		}`
	}
	return `${count} ${PART}${count > 1 && isEnLocale ? 's' : ''}\n(${percent}%)`
}

export const createChartData = (
	numberOfPrintableParts: number,
	numberOfBenefitParts: number,
	benefitName: Exclude<keyof typeof chartNames, 'printable'>
) => {
	const language = getUserLanguage()
	const isEnLocale = language === Locale.en
	const numberOfPrintableWithoutBenefit =
		numberOfPrintableParts - numberOfBenefitParts
	const total = numberOfPrintableParts

	if (!numberOfPrintableParts) {
		// we need to draw empty green chart
		return [
			{
				x: '',
				y: 0,
				label: '',
				name: chartNames[benefitName]
			},
			{
				x: '',
				y: 1,
				label: '',
				name: chartNames.printable
			}
		]
	}

	const printableWithoutBenefitPercent = calcultePercent(
		numberOfPrintableWithoutBenefit,
		total
	)
	const benefitPercent = calcultePercent(numberOfBenefitParts, total)

	const printableWithoutBenefitsLabel = countToPieChartLongLabel(
		numberOfPrintableWithoutBenefit,
		printableWithoutBenefitPercent,
		undefined,
		isEnLocale
	)
	const printableWithoutBenefitsShortLabel = countToPieChartLabel(
		printableWithoutBenefitPercent,
		numberOfPrintableWithoutBenefit,
		benefitName,
		isEnLocale
	)

	const benefitLabel = countToPieChartLongLabel(
		numberOfBenefitParts,
		benefitPercent,
		benefitName,
		isEnLocale
	)
	const benefitShortLabel = countToPieChartLabel(
		benefitPercent,
		numberOfBenefitParts,
		undefined,
		isEnLocale
	)

	return [
		{
			x: benefitShortLabel,
			y: numberOfBenefitParts,
			label: benefitLabel,
			name: chartNames[benefitName]
		},
		{
			x: printableWithoutBenefitsShortLabel,
			y: numberOfPrintableWithoutBenefit,
			label: printableWithoutBenefitsLabel,
			name: chartNames.printable
		}
	]
}

export const getPartsPropertiesData = (partsData: Array<any>) => {
	const empty = [[], []]
	const modifiedArrays: [any[], any[]] | never[][] =
		(partsData.length &&
			partition(partsData, { drawingStatusCode: InapplicableStatus })) ||
		empty

	return {
		filteredPartsData: modifiedArrays[1],
		inapplicablePartsProperties: modifiedArrays[0]
	}
}

export const filterDrawingStatusParts = (parts: Array<any>) => {
	return parts.length > 1
		? parts.filter(part => part.drawingStatusCode !== InapplicableStatus)
		: parts
}

export const createPaginationData = (
	page: number,
	limit: number,
	totalPartsCount: number,
	currentPartsCount: number
) => {
	const totalPagesCount = Math.ceil(totalPartsCount / limit)
	const enableNext = totalPagesCount > page
	const enablePrev = page > 1
	const isLastPage = currentPartsCount < partsPageLimit
	const showingFrom = limit * (page - 1) + 1
	const showingTo = isLastPage
		? limit * (page - 1) + currentPartsCount
		: limit * page
	return {
		page,
		limit,
		totalPartsCount,
		totalPagesCount,
		enableNext,
		enablePrev,
		showingFrom,
		showingTo
	}
}

export const partToResultLabel = (result: string) => {
	const { title } = PART_RESULTS_OPTIONS.find(
		(x: PartResultName) => x.value === result
	)
	return title
}

export const createPartsToPrintSummary = (parts: Part[]) => {
	const partsToPrintSummary = [] as IPartsToPrintSummary[]
	parts.forEach((part: Part) => {
		const partResult = part.result
		const MissingAndNotCostEffective =
			part.status === PartStatus.missingInformation &&
			partResult !== CADAnalysisResult.notCostEffective
		const isInapplicable =
			part?.isDrawing && part?.drawingStatusCode === InapplicableStatus
		const partResultStatus = partToResultLabel(
			isInapplicable
				? INAPPLICABLE
				: MissingAndNotCostEffective
				? PartStatus.missingInformation
				: partResult
		)
		const iconName = MissingAndNotCostEffective
			? PartStatus.missingInformation
			: partResult || CADAnalysisResult.notPrintable
		const statusIndex = partsToPrintSummary.findIndex(
			item => item.status === partResultStatus
		)
		if (statusIndex >= 0) {
			partsToPrintSummary[statusIndex].count += 1
		} else {
			partsToPrintSummary.push({
				status: partResultStatus,
				icon: iconName,
				count: 1
			})
		}
	})
	return partsToPrintSummary
}

export const getPartFailedIssues = (
	configuration?: IConfiguration,
	configurationPrintIssuesAll?: PartPrintIssue[],
	orientationVector?: number[],
	isSmallPart?: boolean,
	user?: IUser
): PartFailedIssueWithMessage[] => {
	if (!configuration || !configurationPrintIssuesAll || !orientationVector) {
		return []
	}
	const configurationPrintIssues = configurationPrintIssuesAll.filter(
		issue => issue.active
	)
	const {
		partSizeIssue,
		orientationSizeIssue,
		configurationSizeIssue,
		wallThicknessIssue,
		overhangingIssue,
		tolerancesIssue,
		holesIssue,
		CADIssue,
		CNCIssue,
		printStabilityIssue,
		orientedCNCIssue,
		heatDeformationIssue,
		threadsIssue,
		internalCavitiesIssue
	} = getIssues(configurationPrintIssues, orientationVector)

	const sizeScore = getSizeScore(
		partSizeIssue,
		orientationSizeIssue,
		configurationSizeIssue
	)

	const analysisSizes = [
		sizeScore,
		getResultScore(CADIssue),
		getResultScore(holesIssue),
		getResultScore(threadsIssue),
		getResultScore(tolerancesIssue),
		getResultScore(overhangingIssue),
		getResultScore(wallThicknessIssue),
		getResultScore(CNCIssue),
		getResultScore(printStabilityIssue),
		getResultScore(orientedCNCIssue),
		getResultScore(heatDeformationIssue),
		getResultScore(internalCavitiesIssue)
	]

	const solutionWithError = checkResultsIssues(analysisSizes)

	const materialScore = getMaterialScore(
		configuration?.solution,
		configuration,
		solutionWithError
	)

	// we need material issue to know its order
	const materialPrintIssue = user?.printIssues?.find(
		(issue: PrintIssue) => issue.id === PrintIssueId.Material
	)

	const printIssueHints = getString('PRINT_ISSUE_HINTS') || {}

	if (sizeScore === printStatusScore.failed) {
		const partIsTooSmallOnConfigLevel = configurationPrintIssues.find(
			issue =>
				issue.printIssueId === PrintIssueId.Size &&
				issue.message === getString('PART_MIN_DIMENSIONS_LIMITATION_FAILED')
		)
		return [
			{
				id: partSizeIssue?.printIssue.id,
				order: partSizeIssue?.printIssue.order,
				message:
					isSmallPart || partIsTooSmallOnConfigLevel
						? getString('PART_SIZE_TOO_SMALL')
						: printIssueHints[partSizeIssue?.printIssue.id as number]
			}
		]
	}

	const failedIssues = [] as PartFailedIssueWithMessage[]
	const isMaterialMetalType =
		configuration.solution?.printerMaterial?.type === materialTypes.metal

	if (
		wallThicknessIssue &&
		wallThicknessIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: wallThicknessIssue?.printIssue.id,
			order: wallThicknessIssue?.printIssue.order,
			message: printIssueHints[wallThicknessIssue?.printIssue.id as number]
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.OVERHANGING, user?.features) &&
		configuration?.solution?.printer?.hasSupportIssues &&
		overhangingIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: overhangingIssue?.printIssue.id,
			order: overhangingIssue?.printIssue.order,
			message: printIssueHints[overhangingIssue?.printIssue.id as number]
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.TOLERANCES, user?.features) &&
		tolerancesIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: tolerancesIssue?.printIssue.id,
			order: tolerancesIssue?.printIssue.order,
			message: printIssueHints[tolerancesIssue?.printIssue.id as number]
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.HOLES_ANALYSIS, user?.features) &&
		holesIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: holesIssue?.printIssue.id,
			order: holesIssue?.printIssue.order,
			message: printIssueHints[holesIssue?.printIssue.id as number]
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.THREAD_DETECTION, user?.features) &&
		threadsIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: threadsIssue?.printIssue.id,
			order: threadsIssue?.printIssue.order,
			message: printIssueHints[threadsIssue?.printIssue.id as number]
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.INTERNAL_CAVITY, user?.features) &&
		internalCavitiesIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: internalCavitiesIssue?.printIssue.id,
			order: internalCavitiesIssue?.printIssue.order,
			message: printIssueHints[internalCavitiesIssue?.printIssue.id as number]
		})
	}

	if (
		Feature.isFeatureOn(FeatureComponentId.CAD_FILE_INTACT, user?.features) &&
		CADIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: CADIssue?.printIssue.id,
			order: CADIssue?.printIssue.order,
			message: printIssueHints[CADIssue?.printIssue.id as number]
		})
	}

	if (materialScore === printStatusScore.failed) {
		failedIssues.push({
			id: materialPrintIssue?.id,
			order: materialPrintIssue?.order,
			message: printIssueHints[materialPrintIssue?.id as number]
		})
	}

	if (
		configuration?.solution?.printerTechnology?.hasStabilityIssues &&
		printStabilityIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: printStabilityIssue?.printIssue.id,
			order: printStabilityIssue?.printIssue.order,
			message: printIssueHints[printStabilityIssue?.printIssue.id as number]
		})
	}

	if (
		isMaterialMetalType &&
		configuration?.solution?.printerTechnology?.machiningIncluded &&
		Feature.isFeatureOn(
			FeatureComponentId.CNC_ORIENTED_SUPPORT_REMOVAL,
			user?.features
		) &&
		orientedCNCIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: orientedCNCIssue?.printIssue.id,
			order: orientedCNCIssue?.printIssue.order,
			message: printIssueHints[orientedCNCIssue?.printIssue.id as number]
		})
	}

	if (
		isMaterialMetalType &&
		Feature.isFeatureOn(
			FeatureComponentId.CNC_SUPPORT_REMOVAL,
			user?.features
		) &&
		configuration?.solution &&
		CNCIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: CNCIssue?.printIssue.id,
			order: CNCIssue?.printIssue.order,
			message: printIssueHints[CNCIssue?.printIssue.id as number]
		})
	}

	if (
		isMaterialMetalType &&
		configuration?.solution &&
		Feature.isFeatureOn(FeatureComponentId.HEAT_DEFORMATION, user?.features) &&
		heatDeformationIssue?.score === printStatusScore.failed
	) {
		failedIssues.push({
			id: heatDeformationIssue?.printIssue.id,
			order: heatDeformationIssue?.printIssue.order,
			message: printIssueHints[heatDeformationIssue?.printIssue.id as number]
		})
	}

	return failedIssues.sort((a, b) => (a?.order || 0) - (b?.order || 0))
}

export const addReasonsToUnprintableParts = (
	parts: Part[],
	leadingConfigurationData: IBestMatchData[],
	user: IUser
) => {
	const partsWithUnprintableReasons = parts.reduce((acc, part) => {
		if (part.result !== CADAnalysisResult.notPrintable) {
			acc.push(part)
		} else {
			const leadingConfigurationPartData = leadingConfigurationData?.find(
				data => data.part === part.id
			)

			const configurationPrintIssues =
				leadingConfigurationPartData?.partPrintIssues
					? leadingConfigurationPartData?.partPrintIssues.filter(
							partPrintIssue =>
								partPrintIssue.configurationId ===
									leadingConfigurationPartData?.configuration?.id ||
								!partPrintIssue.configurationId
					  )
					: []

			const partFailedIssues = getPartFailedIssues(
				leadingConfigurationPartData?.configuration,
				configurationPrintIssues,
				leadingConfigurationPartData?.trayOrientation?.trayNormalVector,
				part.smallPart,
				user
			)
			part.unprintableReason = partFailedIssues?.length
				? partFailedIssues[0].message
				: ''
			part.extraUnprintableReasonsCount = partFailedIssues?.length
				? partFailedIssues?.length - 1
				: 0
			acc.push(part)
		}
		return acc
	}, [] as Part[])

	return partsWithUnprintableReasons
}

export const showFailedPartsWarning = (
	numberOfFailedParts: number,
	totalPartsCount: number,
	forcePublished: boolean,
	allPartsAnalyzed: boolean
) => {
	if (!forcePublished) return false
	if (!allPartsAnalyzed) return true
	const minPercentageOfFailedParts = 15
	return (
		(numberOfFailedParts * 100) / totalPartsCount >= minPercentageOfFailedParts
	)
}
