import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { GET_WEIGHT_REDUCTION_BUNDLE_SUCCESS } from '../../../global actions/types'
import { SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER } from '../../../global actions/types/CastorBannerTypes'
import { IPartsResults } from '../ProjectAnalysis/ProjectAnalysisInterfaces'
import {
	GET_PROJECT_ANALYSIS_NO_PERMISSION,
	HANDLE_LOADER
} from 'global actions/types'
import { store } from 'index'
import { CastorBannerType } from 'Scenes/Components/CastorBanner/CastorBannerType.enum'
import { ReanalyzingStatus } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import { removeUpdatingProjectBanner } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisActions'
import {
	GET_PROJECTS_BUNGLE,
	RESET_PROJECTS_BUNGLE
} from 'Scenes/Home/ProjectBundle/ProjectBundleTypes'
import { USER_HAS_NO_PERMISSION } from 'Services/Constants'
import history from 'Services/history'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	getProjectPrintableClusters,
	getProjectsBundleAnalysis
} from 'Services/Network'
import { getWeightReductionRequest } from 'Services/Network/ProjectAnalysisNetwork'
import { partConfigurationsRoute } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

export const getProjectsBundle = (projectId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: RESET_PROJECTS_BUNGLE
		})

		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		try {
			const { data } = await getProjectsBundleAnalysis(projectId)
			const reanalyzingStatus = data.projects[0].reanalyzingStatus
			const needToShowPopup = reanalyzingStatus === ReanalyzingStatus.pending

			removeUpdatingProjectBanner()

			if (needToShowPopup) {
				let bannerText = getString('BANNER_PROJECT_QUEUED_TO_RECALCULATE')
				let bannerType = CastorBannerType.QUEUED_TO_RECALCULATE

				dispatch({
					type: SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER,
					payload: {
						text: bannerText,
						type: bannerType
					}
				})
			}

			const { data: clustersData } = await getProjectPrintableClusters(
				null,
				projectId
			)

			const { partsResults }: { partsResults: IPartsResults } = data || {}

			const skipProjectPage =
				Feature.isFeatureOn(FeatureComponentId.SKIP_PROJECT_PAGE) &&
				partsResults?.notCostEffectiveCount === 0

			// Skip to part if we have global skip or if there is only one cost-effective part.
			if (
				partsResults?.skipToPartId &&
				(Feature.isFeatureOn(FeatureComponentId.GLOBAL_SKIP_PROJECT_PAGE) ||
					skipProjectPage)
			) {
				const partRoute = partConfigurationsRoute(
					partsResults?.skipToPartProjectId,
					partsResults?.skipToPartId
				)
				history.push(partRoute)
				return
			}

			const userReducer = store.getState().user

			dispatch({
				type: GET_PROJECTS_BUNGLE,
				payload: {
					bundleId: projectId,
					projects: data.projects,
					partsResults,
					features: userReducer.features,
					clusters: clustersData.clusters,
					userId: userReducer.userDetails.id
				}
			})

			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})

			if (partsResults?.weightReductionParts[0]?.isWeightReductionLoading) {
				const weightReductionResponse = await getWeightReductionRequest(
					projectId
				)

				partsResults.weightReductionParts =
					weightReductionResponse.data.weightReductionParts
				partsResults.weightReductionPartsLeadingData =
					weightReductionResponse.data.weightReductionPartsLeadingData
				dispatch({
					type: GET_WEIGHT_REDUCTION_BUNDLE_SUCCESS,
					payload: {
						bundleId: projectId,
						projects: data.projects,
						weightReductionParts:
							weightReductionResponse.data.weightReductionParts,
						weightReductionPartsLeadingData:
							weightReductionResponse.data.weightReductionPartsLeadingData,
						features: userReducer.features,
						clusters: clustersData.clusters,
						allPartsNotCostEffective: partsResults?.allPartsNotCostEffective,
						userId: userReducer.userDetails.id
					}
				})
			}
		} catch (err: any) {
			console.error(err)
			if (err.code === USER_HAS_NO_PERMISSION) {
				dispatch({
					type: GET_PROJECT_ANALYSIS_NO_PERMISSION,
					payload: err.message
				})
			}
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}
