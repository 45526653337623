import parseHTML from 'html-react-parser'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import fileSaver from 'file-saver'
import { find } from 'lodash'

import { store } from '../../..'
import {
	CHANGE_FILTER_PHRASE,
	CHANGE_PARTS_PAGE,
	CHANGE_PARTS_PROPERTIES_PAGE,
	CHANGE_PARTS_VIEW,
	CHANGE_SEARCH_PHRASE,
	CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP,
	CONFIGURATION_CALCULATED,
	DO_REFRESH_CONFIGURATIONS,
	GET_PARTS_PROPERTIES_LOADING,
	GET_PROJECT_ANALYSIS,
	GET_PROJECT_ANALYSIS_FAILED,
	GET_PROJECT_ANALYSIS_NO_PERMISSION,
	GET_PROJECT_ANALYSIS_SUCCESS,
	GET_PROJECT_FEA_ANALYSIS_SUCCESS,
	GET_WEIGHT_REDUCTION_SUCCESS,
	GRAB_CAD_PRINT_UPDATED,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	MORE_PROJECT_PART_WEIGHT_REDUCTION,
	NEW_PAGE_VISITED,
	OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP,
	PROJECT_ANALYSIS_CLUSTER_REMOVED,
	PROJECT_ANALYSIS_PART_REMOVED,
	PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR,
	PROJECT_ANALYSIS_REMOVE_ALERT,
	PROJECT_ANALYSIS_REMOVE_PART_CANCELED,
	PROJECT_ANALYSIS_REMOVE_PART_CLICKED,
	PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED,
	PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED,
	PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED,
	PROJECT_FEA_ANALYSIS_CANCELLED,
	PROJECT_FEA_ANALYSIS_DATA_FETCHED,
	PROJECT_FEA_ANALYSIS_LOADER_TOGGLED,
	PROJECT_PART_WEIGHT_REDUCTION_CANCELLED,
	PROJECT_PART_WEIGHT_REDUCTION_SENT,
	PROJECT_PARTS_PROPERTIES_CALCULATING,
	PROJECT_PARTS_PROPERTIES_CALCULATING_RESET,
	PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA,
	PROJECT_PARTS_PROPERTIES_FETCHED,
	PROJECT_PARTS_PROPERTIES_RESET,
	PROJECT_PARTS_PROPERTIES_UPDATE_PART,
	PROJECT_WEIGHT_REDUCTION_CHANGED,
	PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED,
	PROJECT_WEIGHT_REDUCTION_SUBMIT_TOGLLED,
	PROJECT_WEIGHT_REDUCTION_SUBMITED,
	PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED,
	PROJECT_WEIGHT_REDUCTION_UPDATED,
	RESET_WEIGHT_REDUCTION_PROGRESS_TOUR_FLAG,
	SEND_PROJECT_FAILED_TO_ADMINS,
	USER_HOME_CRUMB_NAME_CHANGED,
	USER_HOME_PROJECT_REMOVED
} from '../../../global actions/types'
import {
	ALERT_CALCULATION_FINISHED,
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../global actions/types/CastorAlertTypes'
import {
	SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER,
	SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER_CANCEL
} from '../../../global actions/types/CastorBannerTypes'
import { UPDATE_PART_STATUS } from '../../../global actions/types/partAnalysisTypes'
import { TOUR_STARTED } from '../../../global actions/types/takeATourTypes'
import { gerUrlParams } from '../../../Services/Utils/urlTools'
import { UploadProjectServiceBuilder } from '../../../themes/Builders/UploadProjectServiceBuilder'
import { AlertType } from '../../Components/alerts/AlertTypes'
import { filterParts } from '../../Components/FilterPartsGrid/FilterPartsAction'
import { SimpleConfigurationSelectorService } from '../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { GeometryAnalysisPartEnum } from '../Customize/CustomizePartFiltering/PartFilteringService'
import { getFeaData } from '../NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionFea/SolutionFeaContentService'
import { ProjectUrlParams } from '../ProjectPage/ProjectPageConstants'
import { getPartsPropertiesData } from './ProjectAnalysisService'
import queryString from 'query-string'
import { CastorBannerType } from 'Scenes/Components/CastorBanner/CastorBannerType.enum'
import { ReanalyzingStatus } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import {
	ACCESS_LOG_FAILED,
	ACCESS_LOG_SUCCESS,
	ActionType,
	FORBIDDEN_ERROR_CODE,
	leadingPopupLSKey,
	partResults,
	USER_HAS_NO_PERMISSION
} from 'Services/Constants'
import history from 'Services/history'
import { setJsonItemToLocalStorage } from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { PartStatus, WeightReductionType } from 'Services/models/IPart'
import { ProjectStatus } from 'Services/models/IProject'
import { FeaResult, FeaStatus } from 'Services/models/ISolutionFea'
import { WeightReductionGen2Type } from 'Services/models/IWeightReductionGen2'
import {
	addAccessLog,
	exportPartsToXLSX,
	getProjectAnalysis,
	getWeightReductionData,
	updateProjectDrawingStatus
} from 'Services/Network'
import {
	getFeaAnalysisResults,
	getPartsProperties,
	getSolutionFea,
	partsPropertiesChange,
	partsPropertiesReset,
	saveFeaAnalysisData,
	updateNotCostEffectivePartResult,
	updatePartWeightReductionType
} from 'Services/Network/PartAnalysisNetwork'
import {
	cancelProject,
	getMoreWeightReductionRequest,
	getWeightReductionRequest,
	removePart,
	removeProject,
	updateGrabCad,
	updateStandardCosts,
	updateWeightReduction,
	weightReductionGen2Request,
	weightReductionRequest
} from 'Services/Network/ProjectAnalysisNetwork'
import { sendProjectFailedToAdmins } from 'Services/Network/UserNetwork'
import {
	partConfigurationsRoute,
	partPropertiesRoute,
	projectNotUploadedRoute,
	projectRoute,
	uploadProjectRoute
} from 'Services/routeFuncs'
import {
	ARE_YOU_SURE_CANCEL_PROJECT,
	COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
	COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
	CONFIGURATION_CALCULATION_ERROR,
	DELETE_ITEM,
	NO,
	OK,
	PROJECT_ANALYSIS_CANCEL_PROJECT,
	PROJECT_ANALYSIS_DELETE_PART_ALERT,
	PROJECT_ANALYSIS_REMOVE_PROJECT,
	PROJECT_ANALYSIS_REMOVE_PROJECT_ERROR,
	PROJECT_ANALYSIS_REMOVE_PROJECT_TEXT,
	PROJECT_ANALYSYS_ERROR_DOWNLOAD,
	PROJECT_ANALYSYS_ERROR_REMOVE_PART,
	REQUEST_FAILED,
	SHOW_NOTIFICATION,
	YES
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

let selectedFilterPart = ''

export const setupProjectAnalysis = (
	projectId,
	filterPartsDefaultSelection,
	pageName,
	tourPages,
	pagesVisited,
	feaId,
	printersFullData
) => {
	return async dispatch => {
		const { search: searchParam } = gerUrlParams([ProjectUrlParams.search])

		dispatch({ type: GET_PROJECT_ANALYSIS, payload: projectId })
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})

		if (pageName) {
			dispatch({
				type: NEW_PAGE_VISITED,
				payload: pageName
			})
		}

		try {
			const simpleConfigurationSelectorService =
				new SimpleConfigurationSelectorService(printersFullData)
			let response = await getProjectAnalysis(projectId)

			dispatch(
				addBannerForUpdatingProject(response?.data?.project?.reanalyzingStatus)
			)

			let {
				project: {
					name,
					status,
					id,
					initialPartsCount,
					lockedParts,
					showDrawingStatusWarn,
					totalParts
				} = {},
				partsResults
			} = response?.data || {}

			if (!response?.data) {
				throw new Error(`project ${projectId}: has an error`)
			}

			const skipProjectPage =
				Feature.isFeatureOn(FeatureComponentId.SKIP_PROJECT_PAGE) &&
				partsResults?.notCostEffectiveCount === 0 &&
				initialPartsCount + lockedParts === 1

			// Skip to part if we have global skip or if there is only one cost-effective part.
			if (
				partsResults?.skipToPartId &&
				(Feature.isFeatureOn(FeatureComponentId.GLOBAL_SKIP_PROJECT_PAGE) ||
					skipProjectPage)
			) {
				const partRoute = partConfigurationsRoute(
					id,
					partsResults?.skipToPartId
				)
				history.push(partRoute)
			}
			const searchParams = queryString.parse(window.location.search)
			const problemHash = searchParams?.problemHash
			if (feaId && !problemHash) {
				//we can assume that the user started from WR because its project page
				//change the part weight status to  suitable for weight reduction, in the server and in the client
				const {
					data: { solutionFea }
				} = await getSolutionFea(feaId)

				await rollbackPartToSuitableForWR(
					solutionFea,
					partsResults?.weightReductionParts
				)
			}

			if (feaId && problemHash) {
				const {
					data: { feaAnalysisResults, solutionFea, solution }
				} = await getFeaAnalysisResults(problemHash, feaId)

				const partPreparingForWR =
					solutionFea?.part?.weightReductionType ===
					WeightReductionType.PREPARING_FOR_WEIGHT_REDUCTION

				if (solutionFea?.status === FeaStatus.awaitingAnalysis) {
					const feaData = getFeaData(solution, solutionFea, feaAnalysisResults)
					dispatch({
						type: GET_PROJECT_FEA_ANALYSIS_SUCCESS,
						payload: {
							showFeaAnalysisProjectAlert: true,
							solutionFea,
							...feaData
						}
					})
				} else if (
					solutionFea?.result === FeaResult.failed &&
					partPreparingForWR
				) {
					await rollbackPartToSuitableForWR(
						solutionFea,
						partsResults?.weightReductionParts
					)
				} else if (partPreparingForWR) {
					await weightReductionGen2Request(
						solutionFea.configuration.id,
						WeightReductionGen2Type.COMPLEX
					)

					dispatch({
						type: PROJECT_PART_WEIGHT_REDUCTION_SENT,
						payload: { partId: solutionFea.part.partId }
					})

					dispatch({
						type: ALERT_POPPED,
						payload: {
							headerTitle: getString('WEIGHT_REDUCTION_FEA_REQ_SUCCEED_HEADER'),
							confirmText: getString('OK'),
							text: getString('WEIGHT_REDUCTION_FEA_SUCCEEDED'),
							showCancel: false,
							onConfirm: () => {
								dispatch({ type: ALERT_POPUP_CANCELED })
							}
						}
					})
				}
			}

			const userReducer = store.getState().user

			dispatch({
				type: GET_PROJECT_ANALYSIS_SUCCESS,
				payload: {
					...response.data,
					partsResults,
					WeightReductionMaterialsData: getWeightReductionMaterialsData(
						simpleConfigurationSelectorService
					),
					features: userReducer.features,
					userId: userReducer.userDetails.id
				}
			})

			if (
				status === ProjectStatus.published &&
				showDrawingStatusWarn &&
				partsResults.inapplicableParts?.length > 0
			) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						alertType: AlertType.WARNING,
						headerTitle: getString('INAPPLICABLE_POPUP_TITLE'),
						confirmText: getString('OK'),
						text: getString(
							totalParts === partsResults.inapplicableParts.length
								? 'ONLY_INAPPLICABLE_POPUP_BODY'
								: 'INAPPLICABLE_POPUP_BODY'
						).format(totalParts, partsResults.inapplicableParts.length),
						showCancel: false,
						onConfirm: async () => {
							await updateProjectDrawingStatus(projectId)
							dispatch({ type: ALERT_POPUP_CANCELED })
						}
					}
				})
			}

			if (searchParam) {
				dispatch({
					type: CHANGE_SEARCH_PHRASE,
					payload: searchParam
				})
			} else {
				dispatch({
					type: CHANGE_FILTER_PHRASE,
					payload: selectedFilterPart
				})
			}

			dispatch({
				type: USER_HOME_CRUMB_NAME_CHANGED,
				payload: { crumbNewName: name, changeNameWith: 'projectName' }
			})

			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			if (tourPages && status === ProjectStatus.published) {
				updateShowTour(dispatch, pagesVisited, pageName)
			}

			if (partsResults?.weightReductionParts[0]?.isWeightReductionLoading) {
				const totalWeightReduction = partsResults?.weightReductionParts.length
				const weightReductionResponse = await getWeightReductionRequest(
					projectId
				)
				partsResults.weightReductionParts =
					weightReductionResponse.data.weightReductionParts
				partsResults.weightReductionPartsLeadingData =
					weightReductionResponse.data.weightReductionPartsLeadingData
				dispatch({
					type: GET_WEIGHT_REDUCTION_SUCCESS,
					payload: {
						...response.data,
						weightReductionParts:
							weightReductionResponse.data.weightReductionParts,
						weightReductionPartsLeadingData:
							weightReductionResponse.data.weightReductionPartsLeadingData,
						features: userReducer.features,
						allPartsNotCostEffective: partsResults?.allPartsNotCostEffective,
						userId: userReducer.userDetails.id,
						projectHasMoreWeightReductions:
							weightReductionResponse?.data?.projectHasMoreWeightReductions,
						totalWeightReduction
					}
				})
			}
		} catch (error) {
			console.log(error)
			if (error.code === USER_HAS_NO_PERMISSION) {
				dispatch({
					type: GET_PROJECT_ANALYSIS_NO_PERMISSION,
					payload: error.message
				})
			}
			dispatch({ type: GET_PROJECT_ANALYSIS_FAILED, payload: error.message })
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}

export const onGetPartsProperties = (projectId, page, limit) => {
	return async dispatch => {
		try {
			dispatch({
				type: GET_PARTS_PROPERTIES_LOADING,
				payload: { loading: true }
			})
			const {
				data: { partsData, parts, totalPartsPropertiesCount }
			} = await getPartsProperties(projectId, page, limit)

			const { filteredPartsData, inapplicablePartsProperties } =
				getPartsPropertiesData(partsData)
			dispatch({
				type: PROJECT_PARTS_PROPERTIES_FETCHED,
				payload: {
					partsProperties: filteredPartsData,
					inapplicablePartsProperties,
					parts,
					totalPartsPropertiesCount
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: GET_PARTS_PROPERTIES_LOADING,
				payload: { loading: false }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('PART_PROPERTIES_GET_ERROR_MESSAGE')
				}
			})
		}
	}
}

export const onRestAllPartProperties = (projectId, partsProperties) => {
	return async dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('RESET_ALL_MESSAGE'),
				headerTitle: getString('RESET_ALL'),
				alertType: AlertType.WARNING,
				onConfirm: () => {
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
					dispatch(
						onPartsPropertiesReset(
							projectId,
							partsProperties.map(part => part.partId),
							-1
						)
					)
				},
				confirmText: getString('YES'),
				cancelText: getString('NO')
			}
		})
	}
}

export const onPartsPropertiesGoBack = (projectId, prevUrl) => {
	history.push(prevUrl || projectRoute(projectId))

	return {
		type: DO_REFRESH_CONFIGURATIONS,
		payload: { doRefreshConfigurations: true }
	}
}

export const getMoreWeightReduction = (projectId, currentPartCount) => {
	return async dispatch => {
		try {
			const response = await getMoreWeightReductionRequest(
				projectId,
				currentPartCount
			)
			const userReducer = store.getState().user

			const {
				weightReductionParts,
				weightReductionPartsLeadingData,
				projectHasMoreWeightReductions
			} = response.data

			dispatch({
				type: MORE_PROJECT_PART_WEIGHT_REDUCTION,
				payload: {
					weightReductionParts,
					weightReductionPartsLeadingData,
					features: userReducer.features,
					projectHasMoreWeightReductions
				}
			})
		} catch (error) {
			console.error(error.message)
		}
	}
}

export const onPartsPropertiesChange = (
	projectId,
	partsProperties,
	rowIndex,
	page,
	limit
) => {
	return async dispatch => {
		const { isBundle = false } = store.getState().ProjectPageReducer || {}
		const { partsProperties: projectPartsProperties } =
			store.getState().ProjectAnalysisReducer || {}
		const { partsProperties: bundlePartsProperties } =
			store.getState().ProjectBundleReducer || {}
		let bundleId = isBundle ? projectId : ''

		try {
			dispatch({
				type: PROJECT_PARTS_PROPERTIES_CALCULATING,
				payload: { calculating: true, rowIndex }
			})

			const {
				data: {
					partsData,
					parts,
					partsLeadingConfigurationsChanged,
					totalPartsPropertiesCount
				}
			} = await partsPropertiesChange(
				projectId,
				partsProperties,
				bundleId,
				page,
				limit
			)

			const existingPartProperties =
				bundlePartsProperties || projectPartsProperties

			// if we receive not the same parts, update them all
			if (
				partsData[rowIndex]?.partId !== existingPartProperties[rowIndex]?.partId
			) {
				dispatch({
					type: GET_PARTS_PROPERTIES_LOADING,
					payload: { loading: true }
				})
				dispatch({
					type: PROJECT_PARTS_PROPERTIES_CALCULATING_RESET
				})

				const { filteredPartsData, inapplicablePartsProperties } =
					getPartsPropertiesData(partsData)

				dispatch({
					type: PROJECT_PARTS_PROPERTIES_FETCHED,
					payload: {
						partsProperties: filteredPartsData,
						inapplicablePartsProperties,
						parts,
						totalPartsPropertiesCount
					}
				})

				dispatch({
					type: GET_PARTS_PROPERTIES_LOADING,
					payload: { loading: false }
				})
				return
			}

			const { filteredPartsData, inapplicablePartsProperties } =
				getPartsPropertiesData(partsData)

			const partNamesWithNewLeadingConfigurations = parts.reduce(
				(acc, part) => {
					if (partsLeadingConfigurationsChanged[part.id]) {
						acc.push(part.partNumber)
					}
					return acc
				},
				[]
			)

			const userIsInCurrentProject =
				window.location.pathname.includes(projectId)

			if (userIsInCurrentProject) {
				dispatch({
					type: PROJECT_PARTS_PROPERTIES_FETCHED,
					payload: {
						partsProperties: filteredPartsData,
						inapplicablePartsProperties,
						rowIndex,
						parts,
						projectId,
						totalPartsPropertiesCount
					}
				})
			}
			dispatch({
				type: PROJECT_PARTS_PROPERTIES_CALCULATING,
				payload: { calculating: false }
			})

			dispatch({
				type: CONFIGURATION_CALCULATED
			})

			dispatch({
				type: DO_REFRESH_CONFIGURATIONS,
				payload: { doRefreshConfigurations: true }
			})

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('PART_PROPERTIES_UPDATED_MESSAGE')
				}
			})

			if (partNamesWithNewLeadingConfigurations.length > 0) {
				if (userIsInCurrentProject) {
					dispatch(
						showLeadingConfigurationChangedAlert(
							partNamesWithNewLeadingConfigurations
						)
					)
				} else {
					setJsonItemToLocalStorage(
						leadingPopupLSKey(projectId),
						partNamesWithNewLeadingConfigurations
					)
				}
			}
		} catch (error) {
			dispatch({
				type: PROJECT_PARTS_PROPERTIES_CALCULATING,
				payload: { calculating: false }
			})

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString(error.message) || error.message
				}
			})
		}
	}
}

export const showLeadingConfigurationChangedAlert =
	partNamesWithNewLeadingConfigurations => {
		return dispatch => {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: getString('LEADING_CONFIGURATION_CHANGED_HEADER'),
					confirmText: getString('OK'),
					text: (
						<p>
							{partNamesWithNewLeadingConfigurations.length > 1
								? getString(
										'LEADING_CONFIGURATION_CHANGED_MANY_PARTS_DESCRIPTION'
								  )
								: getString(
										'LEADING_CONFIGURATION_CHANGED_ONE_PART_DESCRIPTION'
								  )}
							<br />
							{partNamesWithNewLeadingConfigurations.join(', ')}
						</p>
					),
					showCancel: false,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
					}
				}
			})
		}
	}

export const updatePartProperty = (partId, propIndex, value) => {
	return dispatch => {
		dispatch({
			type: PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA,
			payload: {
				partId,
				propIndex,
				value
			}
		})
	}
}

export const onPartPropertiesClick = (projectId, partId, usePrevUrl) => {
	return dispatch => {
		const prevUrlParam = usePrevUrl
			? {
					prevUrl: window.location.pathname + window.location.search
			  }
			: {}

		dispatch({
			type: PROJECT_PARTS_PROPERTIES_UPDATE_PART,
			payload: {
				partId: partId
			}
		})

		return history.push(partPropertiesRoute(projectId), prevUrlParam)
	}
}

export const onPartsPropertiesReset = (projectId, partsIds, rowIndex) => {
	return async dispatch => {
		const { isBundle = false } = store.getState().ProjectPageReducer || {}
		let bundleId = isBundle ? projectId : ''

		try {
			dispatch({
				type: PROJECT_PARTS_PROPERTIES_RESET,
				payload: { reset: true, rowIndex }
			})
			const {
				data: { partsData, parts, totalPartsPropertiesCount }
			} = await partsPropertiesReset(projectId, partsIds, bundleId)

			const { filteredPartsData, inapplicablePartsProperties } =
				getPartsPropertiesData(partsData)

			dispatch({
				type: PROJECT_PARTS_PROPERTIES_FETCHED,
				payload: {
					partsProperties: filteredPartsData,
					inapplicablePartsProperties,
					rowIndex,
					parts,
					totalPartsPropertiesCount
				}
			})

			dispatch({
				type: PROJECT_PARTS_PROPERTIES_RESET,
				payload: { reset: false, rowIndex }
			})

			dispatch({
				type: CONFIGURATION_CALCULATED
			})

			dispatch({
				type: DO_REFRESH_CONFIGURATIONS,
				payload: { doRefreshConfigurations: true }
			})
		} catch (error) {
			dispatch({
				type: PROJECT_PARTS_PROPERTIES_RESET,
				payload: { reset: false, rowIndex }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.message || getString('PART_PROPERTIES_RESET_ERROR_MESSAGE')
				}
			})
		}
	}
}

export const onProjectFeaAlertCancel = feaId => {
	return async dispatch => {
		try {
			dispatch({ type: PROJECT_FEA_ANALYSIS_CANCELLED })
			saveFeaAnalysisData(feaId, [], '', true)
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onProjectFeaAlertConfirm = (
	feaId,
	userInputs,
	feaAnalysisResultsId,
	configuration,
	partId
) => {
	return async dispatch => {
		try {
			dispatch({ type: PROJECT_FEA_ANALYSIS_LOADER_TOGGLED })
			const {
				data: { solutionFea, feaValues }
			} = await saveFeaAnalysisData(feaId, userInputs, feaAnalysisResultsId)
			dispatch({
				type: PROJECT_FEA_ANALYSIS_DATA_FETCHED,
				payload: { feaValues, solutionFea }
			})
			if (solutionFea.result === FeaResult.failed) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						headerTitle: getString('WEIGHT_REDUCTION_FEA_REQ_FAILED_HEADER'),
						confirmText: getString('OK'),
						text: getString('WEIGHT_REDUCTION_FEA_FAILED'),
						showCancel: false,
						onConfirm: () => {
							dispatch({ type: ALERT_POPUP_CANCELED })
						}
					}
				})
				const response = await updatePartWeightReductionType(
					solutionFea?.part?.id,
					WeightReductionType.SUITABLE_FOR_WR
				)

				// TODO: parts needs to be updated to show the current part in the list of suitable for WR parts
				dispatch({
					type: PROJECT_PART_WEIGHT_REDUCTION_CANCELLED,
					payload: {
						part: response?.data?.part,
						features: store.getState().user.features
					}
				})
				return
			}
			await weightReductionGen2Request(
				configuration.id,
				WeightReductionGen2Type.COMPLEX
			)

			dispatch({
				type: PROJECT_PART_WEIGHT_REDUCTION_SENT,
				payload: { partId }
			})

			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: getString('WEIGHT_REDUCTION_FEA_REQ_SUCCEED_HEADER'),
					confirmText: getString('OK'),
					text: getString('WEIGHT_REDUCTION_FEA_SUCCEEDED'),
					showCancel: false,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
					}
				}
			})
		} catch (error) {
			dispatch({ type: PROJECT_FEA_ANALYSIS_LOADER_TOGGLED })
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const updateShowTour = (dispatch, pagesVisited, pageName) => {
	if (pagesVisited && !pagesVisited.includes(pageName)) {
		dispatch({
			type: TOUR_STARTED,

			payload: {}
		})
	}
}

export const onUpdateWeightReductionButtonClick = (
	projectId,
	projectWeightReductionThreshold
) => {
	return async dispatch => {
		dispatch({ type: PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED })
		try {
			const response = await updateWeightReduction(
				projectId,
				projectWeightReductionThreshold
			)
			const {
				data: { partsResults }
			} = response

			const userReducer = store.getState().user

			dispatch({
				type: PROJECT_WEIGHT_REDUCTION_UPDATED,
				payload: {
					partsResults,
					features: userReducer.features,
					userId: userReducer.userDetails.id
				}
			})
		} catch (error) {
			console.log(error)
			dispatch({ type: PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onWeightReductionThresholdChanged = value => {
	return { type: PROJECT_WEIGHT_REDUCTION_CHANGED, payload: { value } }
}

export const onWeightReductionMinimumThicknessThresholdChanged = value => {
	return {
		type: PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED,
		payload: { value }
	}
}

export const onPartClick = (part, newTab, user, updateStatus = false) => {
	let { id, result, projectId, status, materialId, isOffTheShelf } = part
	if (typeof projectId === 'object' && projectId !== null) {
		projectId = projectId.id
	}
	return async dispatch => {
		try {
			const { bundleId } = store.getState().ProjectBundleReducer
			const projectAnalysis = bundleId
				? store.getState().ProjectBundleReducer
				: store.getState().ProjectAnalysisReducer

			if (
				result === partResults.notCostEffective &&
				status !== PartStatus.awaitingCombinedHeatmap
			) {
				let text = getNotCostEffectiveAlertText(
					part,
					projectAnalysis.geometryAnalysisPart
				)
				return dispatch({
					type: ALERT_POPPED,
					payload: {
						headerTitle: getString(
							'PROJECT_ANALYSIS_NOT_COST_EFFECTIVE_ALERT_TITLE'
						),
						confirmText: getString(
							'PROJECT_ANALYSIS_NOT_COST_EFFECTIVE_ALERT_BUTTON'
						),
						text,
						alertType: AlertType.WARNING,
						onConfirm: async () => {
							try {
								const {
									data: {
										generalData: {
											userSubscriptionDetails: { partsCreditExpired }
										},
										partHasConfigurations
									}
								} = await updateNotCostEffectivePartResult(id)
								dispatch({ type: ALERT_POPUP_CANCELED })
								if (partsCreditExpired) {
									const uploadProjectService = new UploadProjectServiceBuilder()
									uploadProjectService.onSubscriptionPopup(dispatch, user)
									return
								}
								dispatch({ type: CONFIGURATION_CALCULATED })
								dispatch({
									type: PROJECT_PARTS_PROPERTIES_UPDATE_PART,
									payload: {
										partId: id
									}
								})

								if (!partHasConfigurations) {
									if (updateStatus) {
										dispatch({
											type: UPDATE_PART_STATUS,
											payload: { partId: id }
										})
										return
									}
									const { searchPhrase, selectedFilterValues, paginationData } =
										projectAnalysis
									dispatch(
										filterParts(
											bundleId || projectId,
											searchPhrase,
											selectedFilterValues,
											!!bundleId,
											1,
											paginationData.limit,
											false,
											user,
											true
										)
									)
									return
								}

								const partRoute =
									status === PartStatus.missingInformation
										? partPropertiesRoute(projectId)
										: partConfigurationsRoute(projectId, id)
								if (newTab) {
									const win = window.open(partRoute, '_blank')
									win.focus()
									return
								}
								history.push(partRoute)
							} catch (error) {
								console.log(error)
								dispatch({
									type: HANDLE_NOTIFICATION,
									payload: {
										notificationType: SHOW_NOTIFICATION.ERROR,
										notificationMessage: error.message
									}
								})
							}
						}
					}
				})
			}
		} catch (error) {
			console.log(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message
				}
			})
		}

		if (
			status === PartStatus.missingInformation ||
			(isOffTheShelf && !materialId)
		) {
			dispatch({
				type: PROJECT_PARTS_PROPERTIES_UPDATE_PART,
				payload: {
					partId: id
				}
			})
			return history.push(partPropertiesRoute(projectId))
		}

		if (
			result === partResults.failed ||
			result === partResults.dependencyAnalysisFailed
		) {
			return dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: getString('FAILED_PROCESSING_PART_ALERT_TITLE'),
					alertType: AlertType.WARNING,
					confirmText: OK,
					text: part.errorMessage,
					showCancel: false,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
					}
				}
			})
		}

		const partRoute = partConfigurationsRoute(projectId, id)

		if (newTab) {
			const win = window.open(partRoute, '_blank')
			win.focus()
			return
		}

		return history.push(partRoute, { showLeadingTab: true })
	}
}

export const sendWeightReductionRequest = (
	projectId,
	projectWeightReductionThreshold,
	projectWeightReductionMinimumThicknessThreshold
) => {
	return async dispatch => {
		try {
			dispatch({
				type: PROJECT_WEIGHT_REDUCTION_SUBMIT_TOGLLED,
				payload: projectId
			})
			await weightReductionRequest(
				projectId,
				projectWeightReductionThreshold,
				projectWeightReductionMinimumThicknessThreshold
			)
			dispatch({ type: PROJECT_WEIGHT_REDUCTION_SUBMITED, payload: projectId })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
					headerTitle: COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
					showCancel: false,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({ type: PROJECT_WEIGHT_REDUCTION_SUBMIT_TOGLLED })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: CONFIGURATION_CALCULATION_ERROR,
					headerTitle: REQUEST_FAILED,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		}
	}
}

export const removeAlert = () => {
	return { type: PROJECT_ANALYSIS_REMOVE_ALERT }
}

export const exportPartsToExcel = (projectId, selectedFilterPart, bundleId) => {
	return async dispatch => {
		try {
			const { allOrganizations } = store.getState().user
			const blob = await exportPartsToXLSX(
				projectId,
				selectedFilterPart,
				bundleId,
				allOrganizations
			)

			fileSaver.saveAs(blob, 'results.xlsx')
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: PROJECT_ANALYSYS_ERROR_DOWNLOAD
				}
			})
		}
	}
}

export const onRemovePartClick = (partId, partName) => {
	return {
		type: PROJECT_ANALYSIS_REMOVE_PART_CLICKED,
		payload: { partId, partName }
	}
}

export const onRemovePartCancel = () => {
	return {
		type: PROJECT_ANALYSIS_REMOVE_PART_CANCELED
	}
}

export const onRemovePartConfirm = (partId, externalId, isBundle) => {
	return async dispatch => {
		try {
			dispatch({
				type: PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED
			})

			let projectId
			if (isBundle) {
				projectId = store.getState().ProjectBundleReducer.bundleId
			} else {
				projectId = store.getState().ProjectAnalysisReducer.projectId
			}

			const response = await removePart(partId, projectId)
			try {
				await addAccessLog(ActionType.DELETE_PART, ACCESS_LOG_SUCCESS, {
					partId: partId
				})
			} catch (e) {
				console.log(e)
			}

			dispatch({
				type: PROJECT_ANALYSIS_CLUSTER_REMOVED,
				payload: { externalId }
			})

			const userReducer = store.getState().user

			dispatch({
				type: PROJECT_ANALYSIS_PART_REMOVED,
				payload: {
					partId,
					partsResults: response.data.partsResults,
					features: userReducer.features,
					clusters:
						store.getState().PrintableClustersOverviewReducer.clusters.length,
					isBundle,
					userId: userReducer.userDetails.userId
				}
			})
		} catch (error) {
			console.error(error)
			try {
				await addAccessLog(ActionType.DELETE_PART, ACCESS_LOG_FAILED, {
					partId: partId
				})
			} catch (e) {
				console.log(e)
			}

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.code === FORBIDDEN_ERROR_CODE
							? error.message
							: PROJECT_ANALYSYS_ERROR_REMOVE_PART
				}
			})
			dispatch({
				type: PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR,
				payload: { partId }
			})
		}
	}
}

export const onRemoveProjectClick = (
	project,
	history,
	requestedRemovePartName
) => {
	let text = PROJECT_ANALYSIS_REMOVE_PROJECT_TEXT.format(project?.name)
	let headerTitle = PROJECT_ANALYSIS_REMOVE_PROJECT
	if (requestedRemovePartName) {
		text = parseHTML(
			PROJECT_ANALYSIS_DELETE_PART_ALERT.format(
				`<span title="${requestedRemovePartName}" className="ellipsis-text">${requestedRemovePartName}</span>`
			)
		)
		headerTitle = DELETE_ITEM
	}
	return dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				headerTitle,
				confirmText: YES,
				cancelText: NO,
				text,
				alertType: AlertType.WARNING,
				onConfirm: () => onRemoveProjectConfirm(dispatch, project.id, history)
			}
		})
	}
}

export const onCancelProjectClickAction = (
	projectId,
	projectName,
	projectType
) => {
	let text = ARE_YOU_SURE_CANCEL_PROJECT.format(projectName)
	let headerTitle = PROJECT_ANALYSIS_CANCEL_PROJECT

	return dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				headerTitle,
				confirmText: YES,
				cancelText: NO,
				text,
				alertType: AlertType.WARNING,
				onConfirm: () => cancelProjectConfirm(dispatch, projectId, history)
			}
		})
	}
}

export const onStandardCostClick = () => {
	return { type: PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED }
}

export const onPartsStandardCostUpdated = data => {
	return {
		type: PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED,
		payload: { data }
	}
}

export const onPartsStandardConfirmed = (
	data,
	projectId,
	prevPartsStandardCosts
) => {
	return async dispatch => {
		try {
			dispatch({ type: PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED })

			// build data in a proper way
			const newStandardCostData = data.map(row => {
				return { partName: row[0].value, standardCost: row[1].value }
			})
			let prevStandardCostData = prevPartsStandardCosts.map(row => {
				return { partName: row[0].value, standardCost: row[1].value }
			})

			// use only difference and not empty
			const diff = prevStandardCostData.filter(row => {
				const newValue = find(
					newStandardCostData,
					data => data.partName === row.partName
				)
				return !!row.partName && newValue?.standardCost !== row?.standardCost
			})

			// add new fields to prevStandardCostData if they weren't created
			newStandardCostData.forEach(cost => {
				const partIsExist = prevStandardCostData.find(
					prevCost => prevCost.partName === cost.partName
				)
				if (!partIsExist) {
					diff.push(cost)
				}
			})

			// we need to keep partName all the time and send new value or null
			const standardCostData = diff.map(prevCost => {
				const newValue = find(
					newStandardCostData,
					data => data.partName === prevCost.partName
				)
				return {
					partName: prevCost.partName,
					standardCost: newValue?.standardCost || null
				}
			})

			const response = await updateStandardCosts(standardCostData, projectId)
			const { partsResults } = response?.data || {}

			dispatch({
				type: PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED,
				payload: { partsResults, features: store.getState().user.features }
			})
			dispatch({
				type: DO_REFRESH_CONFIGURATIONS,
				payload: {
					doRefreshConfigurations: true,
					afterStandardCostUpdate: true,
					refetchParts: true
				}
			})
		} catch (error) {
			console.error(error.message)
			dispatch({ type: PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}
const cancelProjectConfirm = async (dispatch, projectId, history) => {
	try {
		dispatch({
			type: ALERT_CALCULATION_STARTED
		})
		await cancelProject(projectId)
		dispatch({
			type: USER_HOME_PROJECT_REMOVED,
			payload: { projectId }
		})
		dispatch({
			type: ALERT_CALCULATION_FINISHED
		})
		dispatch({
			type: ALERT_POPUP_CANCELED
		})
		if (
			Feature.isFeatureOn(FeatureComponentId.UPLOAD_PROJECT_PAGE_REDIRECTION)
		) {
			history.push(uploadProjectRoute())
		} else {
			history.push(projectNotUploadedRoute())
		}
	} catch (error) {
		console.error(error)
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: PROJECT_ANALYSIS_REMOVE_PROJECT_ERROR
			}
		})
		dispatch({
			type: ALERT_CALCULATION_FINISHED
		})
	}
}
const onRemoveProjectConfirm = async (dispatch, projectId, history) => {
	try {
		dispatch({
			type: ALERT_CALCULATION_STARTED
		})
		await removeProject(projectId)
		try {
			await addAccessLog(ActionType.DELETE_PROJECT, ACCESS_LOG_SUCCESS, {
				projectId: projectId
			})
		} catch (e) {
			console.log(e)
		}

		dispatch({
			type: USER_HOME_PROJECT_REMOVED,
			payload: { projectId }
		})
		dispatch({
			type: ALERT_CALCULATION_FINISHED
		})
		dispatch({
			type: ALERT_POPUP_CANCELED
		})

		if (
			Feature.isFeatureOn(FeatureComponentId.UPLOAD_PROJECT_PAGE_REDIRECTION)
		) {
			history.push(uploadProjectRoute())
		} else {
			history.push(projectNotUploadedRoute())
		}
	} catch (error) {
		console.error(error)
		try {
			await addAccessLog(ActionType.DELETE_PROJECT, ACCESS_LOG_FAILED, {
				projectId: projectId
			})
		} catch (e) {
			console.log(e)
		}

		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage:
					error.code === FORBIDDEN_ERROR_CODE
						? error.message
						: PROJECT_ANALYSIS_REMOVE_PROJECT_ERROR
			}
		})
		dispatch({
			type: ALERT_CALCULATION_FINISHED
		})
	}
}

const getNotCostEffectiveAlertText = (part, geometryAnalysisPart) => {
	// if (part.isScrew) {
	//   return part.errorMessage
	// }
	if (part.isOffTheShelf) {
		return getString('PROJECT_ANALYSIS_OFF_THE_SHELF_ALERT')
	}
	if (
		part.sheetMetal &&
		!geometryAnalysisPart[GeometryAnalysisPartEnum.sheetMetalAnalyze]
	) {
		return getString('PROJECT_ANALYSIS_SHEET_METAL_ALERT')
	}
	if (part.smallPart) {
		return getString('PROJECT_ANALYSIS_SMALL_PART_ALERT')
	}
	return ''
}

export const onSendProjectFailedToAdmins = projectId => {
	return dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('PROJECT_FAILED_MAIL_SENT_TO_ADMINS'),
				headerTitle: getString('PROJECT_FAILED_HEADER_MAIL_SENT_TO_ADMINS'),
				showCancel: false,
				alertType: AlertType.SUCCESS,
				onConfirm: () => {
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
				},
				confirmText: OK
			}
		})
		sendProjectFailedToAdmins(projectId)
		dispatch({
			type: SEND_PROJECT_FAILED_TO_ADMINS,
			payload: {
				projectId
			}
		})
	}
}
export const reducePartWeightClick = (partId, stepURL, hasBrepData) => {
	return {
		type: OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP,
		payload: { partId, stlFile: !!stepURL, hasBrepData }
	}
}
export const reducePartWeightCloseClick = () => {
	return {
		type: CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP
	}
}
export const resetWeightReductionProgressTourStep = () => {
	return {
		type: RESET_WEIGHT_REDUCTION_PROGRESS_TOUR_FLAG
	}
}

const getWeightReductionMaterialsData = simpleConfigurationSelectorService => {
	let simpleConfigurationMaterialsList = []
	let simpleConfigurationPrinters = []
	let simpleConfigurationSelectorCompanyValue = ''
	let simpleConfigurationSelectorMaterialValue
	let simpleConfigurationSelectorPrinterValue
	const printersCompaniesList =
		simpleConfigurationSelectorService.getCompaniesNamesList()
	simpleConfigurationSelectorCompanyValue = printersCompaniesList[0]
	simpleConfigurationPrinters =
		simpleConfigurationSelectorService.getPrintersNamesList(
			simpleConfigurationSelectorCompanyValue
		)
	simpleConfigurationSelectorPrinterValue =
		simpleConfigurationPrinters.find(
			simpleConfigurationPrinter =>
				simpleConfigurationPrinter.printerId ===
				simpleConfigurationPrinters[0].printerId
		) || ''
	simpleConfigurationMaterialsList =
		simpleConfigurationSelectorService.getPrinterMaterialsList(
			simpleConfigurationSelectorPrinterValue
		)
	simpleConfigurationSelectorMaterialValue =
		simpleConfigurationMaterialsList.find(
			simpleConfigurationMaterial =>
				simpleConfigurationMaterial.id ===
				simpleConfigurationSelectorPrinterValue.materials[0].id
		) || ''
	return {
		printersCompaniesList,
		simpleConfigurationMaterialsList,
		simpleConfigurationSelectorMaterialValue,
		simpleConfigurationPrinters,
		simpleConfigurationSelectorCompanyValue,
		simpleConfigurationSelectorPrinterValue
		// simpleInhouseConfiguration: configuration?.inHouseOn || false
	}
}

const rollbackPartToSuitableForWR = async (solutionFea, parts) => {
	const response = await updatePartWeightReductionType(
		solutionFea?.part?.id,
		WeightReductionType.SUITABLE_FOR_WR
	)
	const part = response?.data?.part
	const partToUpdateIndex =
		parts.length > 0 &&
		parts.findIndex(part => part.id === solutionFea?.part?.id)
	if (partToUpdateIndex !== -1) {
		parts[partToUpdateIndex].weightReductionType =
			WeightReductionType.SUITABLE_FOR_WR
	} else {
		parts.push(part)
	}
}

export const removeUpdatingProjectBanner = () => {
	return dispatch => {
		const bannerType = store.getState().CastorBannerReducer?.type

		if (bannerType === CastorBannerType.QUEUED_TO_RECALCULATE) {
			dispatch({
				type: SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER_CANCEL
			})
		}
	}
}

export const addBannerForUpdatingProject = reanalyzingStatus => {
	return async dispatch => {
		const needToShowPopup = reanalyzingStatus === ReanalyzingStatus.pending

		removeUpdatingProjectBanner()

		if (needToShowPopup) {
			let bannerText = getString('BANNER_PROJECT_QUEUED_TO_RECALCULATE')
			let bannerType = CastorBannerType.QUEUED_TO_RECALCULATE

			dispatch({
				type: SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER,
				payload: {
					text: bannerText,
					type: bannerType
				}
			})
		}
	}
}

export const changePartsView = view => {
	return {
		type: CHANGE_PARTS_VIEW,
		payload: { view }
	}
}

export const changePartsPage = page => {
	return {
		type: CHANGE_PARTS_PAGE,
		payload: { page }
	}
}

export const onToggleGrabCad = (id, toggleGrabCad, configurationId) => {
	return async dispatch => {
		try {
			const response = await updateGrabCad(id, !!toggleGrabCad, configurationId)

			if (response) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('PRINT_WITH_GRABCAD_MESSAGE').format(
							id
						)
					}
				})

				dispatch({
					type: GRAB_CAD_PRINT_UPDATED,
					payload: {
						partId: id,
						configurations: response.data?.configurations,
						toggleGrabCad: !!toggleGrabCad
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.FAILED,
						notificationMessage: getString(
							'PRINT_WITH_GRABCAD_FAIL_MESSAGE'
						).format(id)
					}
				})
			}
		} catch (err) {
			console.error(err)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.FAILED,
					notificationMessage: getString(
						'PRINT_WITH_GRABCAD_FAIL_MESSAGE'
					).format(id)
				}
			})
		}
	}
}

export const changePartsPropertiesPage = page => {
	return {
		type: CHANGE_PARTS_PROPERTIES_PAGE,
		payload: { page }
	}
}
