import { isEmpty } from 'lodash'

import {
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	IN_HOUSE_OTHER_PLUS_BUTTON_CLICK,
	IN_HOUSE_OTHER_PRINTER_MATERIAL_NAME_CHANGED,
	IN_HOUSE_OTHER_PRINTER_MATERIAL_PRICE_CHANGED,
	IN_HOUSE_OTHER_PRINTER_MATERIAL_UNIT_CHANGED,
	IN_HOUSE_PRINTERS_ALERT_CLOSE,
	IN_HOUSE_PRINTERS_ALERT_OPEN,
	IN_HOUSE_PRINTERS_AMOUNT_CHANGED,
	IN_HOUSE_PRINTERS_COMPANY_SELECTED,
	IN_HOUSE_PRINTERS_DATA_RECEIVED,
	IN_HOUSE_PRINTERS_MATERIAL_NAME_ADDED,
	IN_HOUSE_PRINTERS_NAME_SELECTED,
	IN_HOUSE_PRINTERS_ONLY_CHECKBOX_CHANGED,
	IN_HOUSE_PRINTERS_OTHER_COMPANY_SELECTED,
	IN_HOUSE_PRINTERS_OTHER_MATERIAL_CLICKED,
	IN_HOUSE_PRINTERS_OTHER_NAME_SELECTED,
	IN_HOUSE_PRINTERS_PRINTER_ADDED,
	IN_HOUSE_PRINTERS_PRINTER_ADDED_TO_USER,
	IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_ALERT_REMOVED,
	IN_HOUSE_PRINTERS_PRINTER_REMOVED,
	IN_HOUSE_PRINTERS_PRINTER_SUBMITTED,
	IN_HOUSE_PRINTERS_UNMOUNT,
	IN_HOUSE_PRINTERS_USER_ID_SELECTED,
	IN_HOUSE_PRINTERS_USER_PRINTERS_FORMATTED,
	IN_HOUSE_PRINTERS_USER_PRINTERS_RECEIVED,
	PRINTER_ADDED_TO_USER,
	PRINTER_REMOVED_FROM_USER,
	USER_FILTERS_CHANGE
} from '../../../../global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import { store } from 'index'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { OTHER } from 'Services/Constants'
import { IUserFilterNames } from 'Services/models/IUserFilter'
import {
	addPrinterToUser,
	getAllPrintersCompanies,
	getAllUserPrinters,
	removePrinterFromUser,
	removePrintersFromUser,
	sendPrinterNotExistMailToAdmins,
	updateUserCustomizeFilters
} from 'Services/Network'
import {
	IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_TITLE,
	OK,
	SHOW_NOTIFICATION
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { sortArrayOfObjectsInAlphabeticalOrder } from 'Services/Utils/sortingTools'

export const setupInHousePrinters = (
		printersCompanies,
		match,
		organizationId
	) => {
		return async dispatch => {
			if (isEmpty(printersCompanies)) {
				await getPrintersCompaniesData(dispatch, organizationId)
			}
			if (match.params['userId']) {
				await getUserPrinters(
					dispatch,
					parseInt(match.params['userId']),
					organizationId
				)
			} else {
				formatUserPrinters(dispatch)
			}
		}
	},
	getPrintersCompaniesData = async (dispatch, organizationId) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		try {
			const response = await getAllPrintersCompanies(organizationId)
			const printersCompanies = response.data.printerCompanies
			const printers = Object.values(printersCompanies).flat()
			dispatch({
				type: IN_HOUSE_PRINTERS_DATA_RECEIVED,
				payload: { printersCompanies, printers }
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (error) {
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			console.error(error)
		}
	},
	getUserPrinters = async (dispatch, userId, organizationId) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		dispatch({
			type: IN_HOUSE_PRINTERS_USER_ID_SELECTED,
			payload: userId
		})
		try {
			const response = await getAllUserPrinters(userId, organizationId)

			const { printers } = response.data
			dispatch({
				type: IN_HOUSE_PRINTERS_USER_PRINTERS_RECEIVED,
				payload: printers
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (error) {
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			console.error(error)
		}
	},
	onPrinterCompanySelectorChange = (
		selectedPrinterCompany,
		printerMaterialUniqueNames
	) => {
		return {
			type: IN_HOUSE_PRINTERS_COMPANY_SELECTED,
			payload: { selectedPrinterCompany, printerMaterialUniqueNames }
		}
	},
	onPrinterNameSelectorChange = (
		selectedPrinterName,
		printers,
		printersFullData,
		printerMaterialUniqueNames
	) => {
		return dispatch => {
			const selectedPrinter = printers.find(
				printer => printer.name === selectedPrinterName
			) || { name: OTHER, inPrinterNamesList: true }
			const printerNotExist = selectedPrinter.inPrinterNamesList || false

			let printerMaterialsList = []
			if (!printerNotExist) {
				const company = printersFullData.find(
					p => p.companyName === selectedPrinter.company
				)
				if (company && company.printers && company.printers.length) {
					const printer = company.printers.find(
						printer => printer.name === selectedPrinterName
					)
					if (printer && printer.materials && printer.materials.length) {
						printerMaterialsList = sortArrayOfObjectsInAlphabeticalOrder(
							printer.materials,
							'name'
						)
					}
				}
			}

			dispatch({
				type: IN_HOUSE_PRINTERS_NAME_SELECTED,
				payload: {
					printerNotExist,
					selectedPrinter,
					printerMaterialsList
				}
			})
		}
	},
	onPrinterAmountChange = amount => {
		return {
			type: IN_HOUSE_PRINTERS_AMOUNT_CHANGED,
			payload: amount
		}
	},
	onInHousePrinterAdd = (
		userId,
		printerAmount,
		printerNotExist,
		selectedPrinter,
		selectedPrinterName,
		selectedPrinterCompany,
		selectedMaterialIds,
		selectedMaterials,
		otherMaterialsSelected,
		organizationId
	) => {
		return async dispatch => {
			if (printerNotExist) {
				return dispatch(
					sendPrinterNotExistMail(
						{ ...selectedPrinter, company: selectedPrinterCompany },
						selectedPrinterName,
						userId,
						printerAmount,
						selectedMaterials,
						otherMaterialsSelected,
						organizationId
					)
				)
			}
			dispatch({
				type: IN_HOUSE_PRINTERS_PRINTER_SUBMITTED
			})
			selectedPrinter.amount = printerAmount || 1
			try {
				const response = await addPrinterToUser(
					selectedPrinter,
					userId,
					selectedMaterialIds,
					organizationId
				)

				if (otherMaterialsSelected.filter(m => m.name).length) {
					await sendPrinterNotExistMailToAdmins(
						selectedPrinter,
						userId,
						printerAmount,
						selectedMaterials,
						otherMaterialsSelected,
						organizationId
					)

					dispatch({
						type: ALERT_POPPED,
						payload: {
							text: getString('IN_HOUSE_PRINTERS_MATERIAL_NOT_EXIST_CONTENT'),
							headerTitle: IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_TITLE,
							showCancel: false,
							alertType: AlertType.SUCCESS,
							onConfirm: () => {
								dispatch({
									type: ALERT_POPUP_CANCELED
								})
							},
							confirmText: OK
						}
					})
				}

				if (userId) {
					dispatch({
						type: IN_HOUSE_PRINTERS_PRINTER_ADDED_TO_USER,
						payload: response.data.printers
					})
				} else {
					const { configurationId } =
						store.getState().CustomizeInHousePrintersReducer
					dispatch({
						type: PRINTER_ADDED_TO_USER,
						payload: {
							printers: response.data.printers,
							organizationId,
							configurationId
						}
					})
				}

				dispatch({
					type: IN_HOUSE_PRINTERS_PRINTER_ADDED
				})
			} catch (error) {
				dispatch({
					type: IN_HOUSE_PRINTERS_PRINTER_ADDED
				})
			}
		}
	},
	removeUserPrinter = (printer, userId, organizationId, configurationId) => {
		return async dispatch => {
			try {
				const response = await removePrinterFromUser(
					printer,
					userId,
					organizationId
				)

				if (response.data?.printers?.length === 0) {
					const { userFilters } = store.getState().user
					const inHousePrintersFilter = userFilters.find(
						filter => filter.name === IUserFilterNames.inHousePrinters
					)
					inHousePrintersFilter.on = false

					dispatch(
						changeInHouseToggle(
							inHousePrintersFilter,
							userFilters,
							organizationId
						)
					)
				}

				if (userId) {
					dispatch({
						type: IN_HOUSE_PRINTERS_PRINTER_REMOVED,
						payload: response.data.printers
					})
				} else {
					dispatch({
						type: PRINTER_REMOVED_FROM_USER,
						payload: {
							printers: response.data.printers,
							organizationId,
							configurationId
						}
					})
				}
			} catch (error) {
				console.log(error)
			}
		}
	},
	removeUserPrinters = (userId, organizationId, configurationId) => {
		return async dispatch => {
			try {
				const response = await removePrintersFromUser(userId, organizationId)

				if (userId) {
					dispatch({
						type: IN_HOUSE_PRINTERS_PRINTER_REMOVED,
						payload: response.data.printers
					})
				} else {
					dispatch({
						type: PRINTER_REMOVED_FROM_USER,
						payload: {
							printers: response.data.printers,
							configurationId,
							organizationId
						}
					})
				}
			} catch (error) {
				console.log(error)
			}
		}
	},
	formatUserPrinters = dispatch => {
		dispatch({
			type: IN_HOUSE_PRINTERS_USER_PRINTERS_FORMATTED
		})
	},
	sendPrinterNotExistMail = (
		selectedPrinter,
		selectedPrinterName,
		userId,
		printerAmount,
		selectedMaterials,
		otherMaterialsSelected,
		organizationId
	) => {
		return async dispatch => {
			const printerName =
				selectedPrinterName || selectedPrinter?.name || getString('PRINTER')
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_CONTENT').format(
						printerName
					),
					headerTitle: IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_TITLE,
					showCancel: false,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch(removePrinterNotExistAlert())
					},
					onCancel: () => {
						dispatch(removePrinterNotExistAlert())
					},
					confirmText: OK
				}
			})
			try {
				await sendPrinterNotExistMailToAdmins(
					selectedPrinter,
					userId,
					printerAmount,
					selectedMaterials,
					otherMaterialsSelected,
					organizationId
				)
			} catch (error) {
				console.log(error)
			}
		}
	},
	removePrinterNotExistAlert = () => {
		return dispatch => {
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
			dispatch({
				type: IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_ALERT_REMOVED
			})
		}
	},
	onOtherPrinterCompanySelectorChange = (
		selectedOtherPrinterCompany,
		selectedPrinter,
		selectedPrinterCompany
	) => {
		selectedPrinter = {
			...selectedPrinter,
			company:
				selectedPrinterCompany !== OTHER
					? selectedPrinterCompany
					: selectedOtherPrinterCompany
		}
		return {
			type: IN_HOUSE_PRINTERS_OTHER_COMPANY_SELECTED,
			payload: { selectedOtherPrinterCompany, selectedPrinter }
		}
	},
	onOtherPrinterNameSelectorChange = (
		selectedOtherPrinterName,
		selectedPrinterCompany,
		selectedOtherPrinterCompany
	) => {
		const selectedPrinter = {
			name: selectedOtherPrinterName,
			company:
				selectedPrinterCompany !== OTHER
					? selectedPrinterCompany
					: selectedOtherPrinterCompany
		}
		return {
			type: IN_HOUSE_PRINTERS_OTHER_NAME_SELECTED,
			payload: selectedPrinter
		}
	},
	getAllPrinters = async (dispatch, organizationId) => {
		try {
			const { adminSelectedOrganizationId } = store.getState().CustomizeReducer
			const response = await getAllPrintersCompanies(
				organizationId || adminSelectedOrganizationId
			)
			const printersCompanies = response.data.printerCompanies
			const printers = Object.values(printersCompanies).flat()
			dispatch({
				type: IN_HOUSE_PRINTERS_DATA_RECEIVED,
				payload: { printersCompanies, printers }
			})
		} catch (error) {
			console.error(error)
		}
	},
	onInHousePrintersAlertOpen = (organizationId, configurationId) => {
		return async dispatch => {
			await getAllPrinters(dispatch, organizationId)
			formatUserPrinters(dispatch)

			dispatch({
				type: IN_HOUSE_PRINTERS_ALERT_OPEN,
				payload: { organizationId, configurationId }
			})
		}
	},
	onInHousePrintersAlertClose = dispatch => {
		dispatch({ type: IN_HOUSE_PRINTERS_ALERT_CLOSE })
	},
	onInHousePrintersUnmount = payload => {
		return dispatch => {
			dispatch({ type: IN_HOUSE_PRINTERS_UNMOUNT, payload })
		}
	}

export const onMaterialsListChange = selectedMaterials => {
	if (selectedMaterials.includes(OTHER)) {
		return {
			type: IN_HOUSE_PRINTERS_OTHER_MATERIAL_CLICKED
		}
	}
	return {
		type: IN_HOUSE_PRINTERS_MATERIAL_NAME_ADDED,
		IN_HOUSE_PRINTERS_OTHER_MATERIAL_CLICKED,
		payload: {
			selectedMaterials: selectedMaterials.filter(
				m => m !== getString('ADMIN_MATERIALS_PAGE_HEADER') && m !== OTHER
			)
		}
	}
}

export const onOtherMaterialNameSelectorChange = (name, id) => {
	return {
		type: IN_HOUSE_OTHER_PRINTER_MATERIAL_NAME_CHANGED,
		payload: { id, name }
	}
}

export const onOtherPlusButtonChange = (name, id) => {
	return {
		type: IN_HOUSE_OTHER_PLUS_BUTTON_CLICK,
		payload: { id, name }
	}
}

export const onOtherMaterialPriceSelectorChange = (price, id) => {
	return {
		type: IN_HOUSE_OTHER_PRINTER_MATERIAL_PRICE_CHANGED,
		payload: { id, price }
	}
}

export const onOtherMaterialUnitSelectorChange = (unit, id) => {
	return {
		type: IN_HOUSE_OTHER_PRINTER_MATERIAL_UNIT_CHANGED,
		payload: { id, unit }
	}
}

export const touchInHousePrintersOnlyCheckbox = checked => {
	return {
		type: IN_HOUSE_PRINTERS_ONLY_CHECKBOX_CHANGED,
		payload: checked
	}
}

export const changeInHouseToggle = (
	inHouseFilter,
	userFilters,
	organizationId
) => {
	return async dispatch => {
		const inHouseFilterIndex = userFilters.findIndex(
			filter => filter.name === IUserFilterNames.inHousePrinters
		)
		userFilters[inHouseFilterIndex] = inHouseFilter
		const response = await updateUserCustomizeFilters(
			userFilters,
			organizationId
		)
		const newUserFilters = response?.data?._userFilters
		const updatedInHouseFilter = newUserFilters.find(
			filter => filter.name === IUserFilterNames.inHousePrinters
		)
		dispatch({
			type: USER_FILTERS_CHANGE,
			payload: { newUserFilters }
		})

		dispatch(touchInHousePrintersOnlyCheckbox(updatedInHouseFilter.on))
	}
}

export const changeInHousePrintersOnlyCheckbox = (
	inHouseFilter,
	userFilters,
	organizationId
) => {
	return async dispatch => {
		try {
			dispatch(changeInHouseToggle(inHouseFilter, userFilters, organizationId))

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'IN_HOUSE_PRINTERS_FILTER_UPDATE_SUCCESS'
					)
				}
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage ||
						getString('IN_HOUSE_PRINTERS_FILTER_UPDATE_ERROR')
				}
			})
		}
	}
}
