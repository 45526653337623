import React, { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import LinearProgressBar from '../NewUploadProject/ProgressBar/LinearProgressBar'
import { UploadingProjectInitialState } from '../NewUploadProject/UploadingProjectReducer'
import {
	clearPoller,
	clearUploadMessages,
	getFastProjectPoller
} from '../NewUploadProject/UploadProjectActions'
import CancelProject from './CancelProject'
import { usePushNotificationSilentEvent } from 'Scenes/Components/PushNotification/PushNotificationHooks'
import { ReanalyzingStatus } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import ProjectFail from 'Scenes/Home/ProjectPending/ProjectFail'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Project, ProjectStatus } from 'Services/models/IProject'
import { projectRoute } from 'Services/routeFuncs'
import { PROJECT_PENDING_TITLE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import inPogressImg from 'assets/img/svg/inProgress.svg'

import './ProjectDenied.scss'

interface ProjectPendingProps {
	project: Project
	projectBundleId?: string
	isCluster?: boolean
	projects?: Project[]
}

const ProjectPending: FC<ProjectPendingProps> = ({
	project,
	isCluster,
	projectBundleId,
	projects
}) => {
	const { projectId } = useParams() as { projectId: string }
	const dispatch = useDispatch()
	const isProjectBundleOn = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_BUNDLE_PAGE
	)
	const projectStatusFailed = project?.status === ProjectStatus.failed
	const {
		quickProjectPublished,
		quickProjectFailed,
		quickProjectConnectionError
	} = useSelector((state: RootStateOrAny) => state?.uploadProject)
	const [{ bundleId }] = useSelector((state: RootStateOrAny) => {
		return [
			state.UploadingProjectReducer?.projectUploading[projectId || 0] ||
				new UploadingProjectInitialState()
		]
	})
	const { failedSilentConnection } = useSelector((state: RootStateOrAny) => {
		return state?.PushNotificationReducer
	})

	const projectFail = useMemo(
		() => quickProjectFailed || projectStatusFailed || !project,
		[project, projectStatusFailed, quickProjectFailed]
	)

	const clearUploadProcess = () => {
		dispatch(clearUploadMessages(projectId))
		dispatch(clearPoller())
	}

	const allProjectsReanalyzingStatusComplete = projectBundleId
		? projects?.every(
				project => project.reanalyzingStatus === ReanalyzingStatus.complete
		  )
		: project.reanalyzingStatus === ReanalyzingStatus.complete

	//reload project or clear uploading
	useEffect(() => {
		const projectNotFailed =
			quickProjectPublished && !quickProjectFailed && !projectStatusFailed

		if (!isCluster && projectNotFailed && !!project) {
			dispatch(clearPoller())
			window.location.reload()
		}
		// remove poller if project failed
		if (projectFail || quickProjectConnectionError) {
			clearUploadProcess()
		}
	}, [
		quickProjectPublished,
		quickProjectFailed,
		project,
		projectFail,
		quickProjectConnectionError
	])

	//create poller if not failed and not from cluster page
	useEffect(() => {
		if (!isCluster || !projectFail) {
			dispatch(getFastProjectPoller(projectId))
		}
		return () => clearUploadProcess()
	}, [isCluster, projectId, project])

	//if folder then go to project route + restart poller
	useEffect(() => {
		if (
			isProjectBundleOn &&
			!projectBundleId &&
			bundleId &&
			projectId !== projectBundleId &&
			projectId !== bundleId
		) {
			clearUploadProcess()
			window.location.href = projectRoute(bundleId)
		}
	}, [bundleId, projectBundleId])

	usePushNotificationSilentEvent(bundleId || projectId)

	if (projectFail || isCluster || quickProjectConnectionError) {
		return (
			<>
				<ProjectFail
					project={project}
					projectFail={projectFail}
					quickProjectConnectionError={quickProjectConnectionError}
				/>
				<CancelProject
					projectId={project?.id}
					projectName={project?.name}
					projectType={ProjectStatus.failed}
				/>
			</>
		)
	}

	return (
		<NavBarAndMaterial title={PROJECT_PENDING_TITLE}>
			<div className="progress-result">
				<div>
					<img
						src={inPogressImg}
						className="status-viewer-page--image"
						alt="in-progress-img"
					/>
					<div className="status">
						<>
							{failedSilentConnection ? (
								<p className="error">
									{getString('NOTIFICATION_UPLOADING_MESSAGE_FAIL')}
								</p>
							) : (
								<LinearProgressBar hideFiles={true} />
							)}
							{allProjectsReanalyzingStatusComplete ? (
								<CancelProject
									projectId={project?.id}
									projectName={project?.name}
								/>
							) : (
								<></>
							)}
						</>
					</div>
				</div>
			</div>
		</NavBarAndMaterial>
	)
}

export default memo(ProjectPending)
